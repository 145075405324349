import React from 'react'
import PropTypes from 'prop-types'
import styles from './DownloadDocument.module.scss'
import tr from './DownloadDocument.json'

const DownloadDocument = ({
  idHtmlAttr,
  title,
  subtitle,
  sourceUrl,
  locale
}) => (
  <div id={idHtmlAttr} className={styles.wrap}>
    <div className={styles.frame}>
      <h3 className={styles.title}>{title}</h3>
      {subtitle && <h4 className={styles.subtitle}>{subtitle}</h4>}
      <a className={styles.button} href={sourceUrl} target='_blank'>
        <span>{tr.button[locale]}</span>
      </a>
    </div>
  </div>
)

DownloadDocument.propTypes = {
  idHtmlAttr: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  sourceUrl: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired
}

export default DownloadDocument
