import React from 'react'
import PropTypes from 'prop-types'
import styles from './IntroTitle.module.scss'

const IntroTitle = props => {
  let modifiers = []
  if (props.center) modifiers.push(styles.center)
  if (props.leftOverlay) modifiers.push(styles.leftOverlay)
  if (props.rightOverlay) modifiers.push(styles.rightOverlay)
  if (props.noPadding) modifiers.push(styles.noPadding)
  if (props.introTimetable) modifiers.push(styles.introTimetable)
  if (props.gold) modifiers.push(styles.gold)
  if (props.small) modifiers.push(styles.small)
  return (
    <h1 className={`${styles.title} ${modifiers.join(' ')}`}>
      {props.text}
    </h1>
  )
}

IntroTitle.propTypes = {
  text: PropTypes.string.isRequired,
  center: PropTypes.bool,
  leftOverlay: PropTypes.bool,
  rightOverlay: PropTypes.bool,
  noPadding: PropTypes.bool,
  introTimetable: PropTypes.bool,
  gold: PropTypes.bool,
  small: PropTypes.bool
}

export default IntroTitle
