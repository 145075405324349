import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DayPicker from 'react-day-picker'
import styles from './BookFormDayPicker.module.scss'
import './dayPickerStyles.scss'

const MONTHS = {
  es: [
    'enero',
    'febrero',
    'marzo',
    'abril',
    'mayo',
    'junio',
    'julio',
    'agosto',
    'septiembre',
    'octubre',
    'noviembre',
    'diciembre'
  ],
  en: [
    'January',
    'February',
    'March',
    'April',
    'may',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ],
  ca: [
    'gener',
    'febrer',
    'març',
    'abril',
    'maig',
    'juny',
    'juliol',
    'agost',
    'setembre',
    'octubre',
    'novembre',
    'desembre'
  ],
  fr: [
    'janvier',
    'février',
    'mars',
    'avril',
    'mai',
    'juin',
    'juillet',
    'aout',
    'septembre',
    'octobre',
    'novembre',
    'décembre'
  ]
}

const WEEKDAYS_SHORT = {
  es: ['do', 'lu', 'ma', 'mi', 'ju', 'vi', 'sá'],
  en: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
  ca: ['dg.', 'dl.', 'dt.', 'dc.', 'dj.', 'dv.', 'ds.'],
  fr: ['di', 'lu', 'ma', 'me', 'je', 've', 'sa']
}

const WEEKDAYS_LONG = {
  es: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
  en: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  ca: ['diumenge', 'dilluns', 'dimarts', 'dimecres', 'dijous', 'divendres', 'dissabte'],
  fr: ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi']
}

const FIRST_DAY_OF_WEEK = {
  es: 1,
  en: 0,
  ca: 1,
  fr: 1
}

const LABELS = {
  es: { nextMonth: 'Próximo mes', previousMonth: 'Mes anterior' },
  en: { nextMonth: 'Next month', previousMonth: 'Previous month' },
  ca: { nextMonth: 'Proper mes', previousMonth: 'Mes anterior' },
  fr: { nextMonth: 'Mois prochain', previousMonth: 'Mois précédent' }
}

class BookFormDayPicker extends Component {
  constructor (props) {
    super(props)
    this.handleDayClick = this.handleDayClick.bind(this)
  }

  handleDayClick (day, { disabled, selected }) {
    if (disabled) {
      return
    }
    this.props.handleDateChange(day)
  }

  render () {
    const from = this.props.arrival
    const to = this.props.departure
    const today = new Date()
    return (
      <div className={styles.wrap}>
        <DayPicker
          locale={this.props.locale}
          months={MONTHS[this.props.locale]}
          weekdaysLong={WEEKDAYS_LONG[this.props.locale]}
          weekdaysShort={WEEKDAYS_SHORT[this.props.locale]}
          firstDayOfWeek={FIRST_DAY_OF_WEEK[this.props.locale]}
          labels={LABELS[this.props.locale]}
          onDayClick={this.handleDayClick}
          disabledDays={{ before: today }}
          selectedDays={[from, { from, to }]}
          fixedWeeks
        />
      </div>
    )
  }
}

BookFormDayPicker.propTypes = {
  locale: PropTypes.string.isRequired
}

export default BookFormDayPicker
