import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import Icon from '../Icon/Icon'
import Modal from '../Modal/Modal'
import SmallWysiwyg from '../SmallWysiwyg/SmallWysiwyg'
import styles from './GiftCards.module.scss'

const GiftCards = ({ items, contact }) => (
  <div className={styles.wrap} id='ticketRegalo'>
    {items.map((item, i) => {
      const { title } = item
      const props = { title, ...item.acf }
      return <Item key={i} contact={contact} {...props} />
    })}
  </div>
)

GiftCards.propTypes = {
  items: PropTypes.array.isRequired,
  contact: PropTypes.object.isRequired
}

class Item extends Component {
  constructor (props) {
    super(props)
    this.state = {
      showModal: false
    }
  }

  toggleModal () {
    if (this.state.showModal) {
      this.setState({
        showModal: false
      })
    } else {
      this.setState({
        showModal: true
      })
    }
  }

  closeModal () {
    this.setState({
      showModal: false
    })
  }

  render () {
    const {
      image,
      title,
      subtitle,
      short_description,
      intro,
      description,
      button,
      contact
    } = this.props
    const backupSourceUrl = image.source_url
    const imgAltText = image.alt_text
    const localFile = image.localFile
    const sizes = localFile ? localFile.childImageSharp.fluid : null
    return (
      <div className={styles.item}>
        <div className={styles.grid}>
          <div className={styles.imageColumn}>
            {sizes && <Img fluid={sizes} alt={imgAltText} />}
            {!sizes && <img src={backupSourceUrl} alt={imgAltText} />}
          </div>
          <div className={styles.infoColumn}>
            <div className={styles.infoGrid}>
              <div className={styles.infoGridCol}>
                <div className={styles.title}>
                  <span>{title}</span>
                </div>
                {subtitle && (
                  <div className={styles.subtitle}>
                    <span>{subtitle}</span>
                  </div>
                )}
              </div>
              <div className={styles.infoGridCol}>
                {short_description && (
                  <div
                    className={styles.shortDescription}
                    dangerouslySetInnerHTML={{ __html: short_description }}
                  />
                )}
              </div>
            </div>
            <div className={styles.buttonContainer}>
              <button
                type='button'
                className={styles.button}
                onClick={() => {
                  this.toggleModal()
                }}
              >
                <span className={styles.alphaText}>{button.alphaText}</span>
                <Icon name='smallArrowRightGold' />
                <span className={styles.betaText}>{button.betaText}</span>
              </button>
            </div>
          </div>
        </div>
        <Modal
          isOpen={this.state.showModal}
          onRequestClose={() => {
            this.closeModal()
          }}
        >
          <Content
            title={title}
            subtitle={subtitle}
            intro={intro}
            description={description}
            contact={contact}
          />
        </Modal>
      </div>
    )
  }
}

const Content = ({ title, subtitle, intro, description, contact }) => (
  <div className={styles.content}>
    <h2 className={styles.contentTitle}>{title}</h2>
    <h3 className={styles.contentSubtitle}>{subtitle}</h3>
    {intro && (
      <div
        className={styles.intro}
        dangerouslySetInnerHTML={{ __html: intro }}
      />
    )}
    <SmallWysiwyg content={description} />
    <h4 className={styles.contactTitle}>{contact.title}</h4>
    <ul className={styles.contactList}>
      {contact.contact_list.map((item, i) => {
        return (
          <li key={i}>
            <a href={item.link}>{item.text}</a>
          </li>
        )
      })}
    </ul>
  </div>
)

Item.propTypes = {
  image: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  gallery: PropTypes.array,
  intro: PropTypes.string,
  description: PropTypes.string,
  button: PropTypes.object
}

export default GiftCards
