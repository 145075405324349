import React from 'react'
import PropTypes from 'prop-types'
import galleryOpen from './svg/galleryOpen.svg'
import globe from './svg/globe.svg'
import goldCaret from './svg/goldCaret.svg'
import grayDarkerCaret from './svg/grayDarkerCaret.svg'
import grayLightCaret from './svg/grayLightCaret.svg'
import mainFooterBrand from './svg/mainFooterBrand.svg'
import mainHeaderBrand from './svg/mainHeaderBrand.svg'
import mainNavButton from './svg/mainNavButton.svg'
import modalClose from './svg/modalClose.svg'
import modalCloseWhite from './svg/modalCloseWhite.svg'
import smallArrowRightBlack from './svg/smallArrowRightBlack.svg'
import smallArrowLeftGold from './svg/smallArrowLeftGold.svg'
import smallArrowRightGold from './svg/smallArrowRightGold.svg'
import smallArrowRightGray from './svg/smallArrowRightGray.svg'
import shareGoldFacebook from './svg/shareGoldFacebook.svg'
import shareGoldMail from './svg/shareGoldMail.svg'
import shareGoldTwitter from './svg/shareGoldTwitter.svg'
import shareMobile from './svg/shareMobile.svg'
import shareMobileClose from './svg/shareMobileClose.svg'
import shareMobileFacebook from './svg/shareMobileFacebook.svg'
import shareMobileMail from './svg/shareMobileMail.svg'
import shareMobileTwitter from './svg/shareMobileTwitter.svg'
import sharePostFacebook from './svg/sharePostFacebook.svg'
import sharePostMail from './svg/sharePostMail.svg'
import sharePostTwitter from './svg/sharePostTwitter.svg'
import whiteCaret from './svg/whiteCaret.svg'
import locationMapMarker from './svg/locationMapMarker.svg'
import phone from './svg/phone.svg'
import mobileHeaderLocation from './svg/mobileHeaderLocation.svg'
import desktopHeaderLocation from './svg/desktopHeaderLocation.svg'
import whatsapp from './svg/whatsapp.svg'

const Icon = props => {
  const {name, ...attributes} = props

  return (
    <svg {...attributes}>
      <use xlinkHref={`#${name}`} />
    </svg>
  )
}

Icon.propTypes = {
  name: PropTypes.string.isRequired
}

export default Icon
