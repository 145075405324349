import React from 'react'
import PropTypes from 'prop-types'
import styles from './Wysiwyg.module.scss'

const Wysiwyg = props => {
  let modifier = ''
  let sideNoteModifier = ''
  if (props.leftOverlay) {
    modifier = styles.leftOverlay
    sideNoteModifier = styles.sideNoteLeftOverlay
  } else if (props.centerOverlay) {
    modifier = styles.centerOverlay
    sideNoteModifier = styles.sideNoteCenterOverlay
  } else if (props.rightOverlay) {
    modifier = styles.rightOverlay
    sideNoteModifier = styles.sideNoterightOverlay
  } else if (props.centered) {
    modifier = styles.centered
  } else if (props.noSpacing) {
    modifier = styles.noSpacing
  }

  let sideNote = null
  if (props.sideNote) {
    sideNote = (
      <div
        className={`${styles.sideNote} ${sideNoteModifier}`}
        dangerouslySetInnerHTML={{ __html: props.sideNote }}
      />
    )
  }

  return (
    <div className={styles.wrap}>
      <div
        className={`${styles.content} ${modifier} ${
          props.lightBg ? styles.lightBg : ''
        }`}
        dangerouslySetInnerHTML={{ __html: props.content }}
      />
      {sideNote}
    </div>
  )
}

Wysiwyg.propTypes = {
  content: PropTypes.string.isRequired,
  sideNote: PropTypes.string,
  lightBg: PropTypes.bool
}

export default Wysiwyg
