import React from 'react'
import PropTypes from 'prop-types'
import styles from './SectionTitle.module.scss'

const SectionTitle = props => {
  const htmlElement = props.htmlElement || 'h2'
  let title = null
  if (htmlElement === 'h1') {
    title = <h1 className={styles.title}>{props.text}</h1>
  } else if (htmlElement === 'h2') {
    title = <h2 className={styles.title}>{props.text}</h2>
  } else if (htmlElement === 'h3') {
    title = <h3 className={styles.title}>{props.text}</h3>
  } else if (htmlElement === 'div') {
    title = <div className={styles.title}>{props.text}</div>
  }
  return (
    <div className={styles.wrap}>
      <span>{title}</span>
    </div>
  )
}

SectionTitle.propTypes = {
  text: PropTypes.string.isRequired,
  htmlElement: PropTypes.string
}

export default SectionTitle
