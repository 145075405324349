import React from 'react'
import PropTypes from 'prop-types'
import { MediaQueryProvider, MediaQuery } from 'react-responsive-hoc'
import Img from 'gatsby-image'
import styles from './HomeLocation.module.scss'

const HomeLocation = props => {
  const backupSourceUrl = props.data.image.source_url
  const imgAltText = props.data.image.alt_text
  const localFile = props.data.image.localFile
  const sizes = localFile ? localFile.childImageSharp.fluid : null
  const contactItemContent = (item) => {
    let el
    if (item.link) {
      el = <a href={item.link}>{item.text}</a>
    } else {
      el = <span>{item.text}</span>
    }
    return el
  }
  return (
    <div className={styles.wrap}>
      <div className={styles.container}>
        <div className={styles.info}>
          <h2 className={styles.title}>
            {props.data.title}
          </h2>
          <div
            className={styles.content}
            dangerouslySetInnerHTML={{ __html: props.data.content}}
          />
          <ul className={styles.contact}>
            {props.data.contact.map((item, i) => {
              return (
                <li key={`contactItem${i}`}>
                  {contactItemContent(item)}
                </li>
              )
            })}
          </ul>
        </div>
        <MediaQueryProvider width={1440} height={900}>
          <MediaQuery query='(min-width: 683px)'>
            <div className={styles.image}>
              {sizes &&
                <Img fluid={sizes} alt={imgAltText} />
              }
              {!sizes &&
                <div className={styles.imageBackup}>
                  <img src={backupSourceUrl} alt={imgAltText} />
                </div>
              }
            </div>
          </MediaQuery>
        </MediaQueryProvider>
      </div>
    </div>
  )
}

HomeLocation.propTypes = {
  data: PropTypes.object.isRequired
}

export default HomeLocation
