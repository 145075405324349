import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { MediaQueryProvider, MediaQuery } from 'react-responsive-hoc'
import { VelocityComponent } from 'velocity-react'
import DropdownAnimation from '../DropdownAnimation/DropdownAnimation'
import styles from './Amenities.module.scss'
import tr from './Amenities.json'

class ListContent extends Component {
  constructor (props) {
    super(props)
    let lists = []
    const list = this.props.list
    let half
    if (list.length % 2 === 0) {
      half = Math.floor(list.length / 2)
    } else {
      half = Math.floor(list.length / 2) + 1
    }
    const left = list.slice(0, half)
    const right = list.slice(half, list.length)
    lists[0] = left
    lists[1] = right
    this.lists = lists
  }

  render () {
    let addendum = null
    if (this.props.addendum) {
      addendum = (
        <p className={styles.listAddendum}>
          {this.props.addendum}
        </p>
      )
    }
    return (
      <div>
        <p className={styles.listIntro}>
          {this.props.intro}
        </p>
        <div className={styles.listGrid}>
          {this.lists.map((list, i) => {
            return (
              <div key={i} className={styles.listColumn}>
                <ul className={styles.list}>
                  {list.map((item, j) => {
                    return (
                      <li key={j} className={styles.item}>
                        {item.item}
                      </li>
                    )
                  })}
                </ul>
              </div>
            )
          })}
        </div>
        {addendum}
      </div>
    )
  }
}

class Amenities extends Component {
  constructor (props) {
    super(props)

    let lists = []
    const list = this.props.data.list
    let half
    if (list.length % 2 === 0) {
      half = Math.floor(list.length / 2)
    } else {
      half = Math.floor(list.length / 2) + 1
    }
    const left = list.slice(0, half)
    const right = list.slice(half, list.length)
    lists[0] = left
    lists[1] = right

    this.lists = lists
    this.state = {
      dropdownVisible: false,
      sliding: false
    }
    this.toggleDropdown = this.toggleDropdown.bind(this)
  }

  toggleDropdown () {
    if (this.state.dropdownVisible) {
      this.setState({
        ...this.state,
        dropdownVisible: false
      })
    } else {
      this.setState({
        ...this.state,
        dropdownVisible: true
      })
    }
  }

  render () {
    const addendum = this.props.data.list_addendum || null

    return (
      <div className={styles.wrap}>
        <div className={styles.container}>
          <div className={styles.grid}>
            <div className={styles.introColumn}>
              <h2 className={styles.title}>
                {this.props.data.title}
              </h2>
              <div
                className={styles.intro}
                dangerouslySetInnerHTML={{ __html: this.props.data.intro }}
              />
            </div>
            <div className={styles.contentColumn}>
              <MediaQueryProvider width={1440} height={900}>
                <MediaQuery query='(max-width: 976px)'>
                  <button type='button' className={styles.button} onClick={this.toggleDropdown}>
                    {tr.dropdown.button[this.props.locale]}
                  </button>
                  <DropdownAnimation
                    animation={this.state.dropdownVisible ? 'slideDown' : 'slideUp'}
                    easing='easeInOutSine'
                    duration={600}
                  >
                    <div>
                      <VelocityComponent
                        animation={{
                          opacity: this.state.dropdownVisible ? 1 : 0
                        }}
                        delay={this.state.dropdownVisible ? 500 : 0}
                      >
                        <div className={styles.dropdown}>
                          <ListContent
                            intro={this.props.data.list_intro}
                            list={this.props.data.list}
                            addendum={addendum}
                          />
                        </div>
                      </VelocityComponent>
                    </div>
                  </DropdownAnimation>
                </MediaQuery>
                <MediaQuery query='(min-width: 977px)'>
                  <ListContent
                    intro={this.props.data.list_intro}
                    list={this.props.data.list}
                    addendum={addendum}
                  />
                </MediaQuery>
              </MediaQueryProvider>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Amenities.propTypes = {
  data: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired
}

export default Amenities
