import React from 'react'
import PropTypes from 'prop-types'
import ReactModal from 'react-modal'
import Icon from '../Icon/Icon'
import './modalStyles.scss'

const Modal = props => {
  let sizeModifier = ''
  if (props.small) {
    sizeModifier = '-small'
  }
  return (
    <ReactModal
      isOpen={props.isOpen}
      className={`Modal ${sizeModifier}`}
      overlayClassName='ModalOverlay'
      onRequestClose={props.onRequestClose}
      closeTimeoutMS={400}
      ariaHideApp={false}
    >
      <div className='ModalContent'>
        <div className='ModalInner'>
          {props.children}
          <button type='button' className='CloseModal' onClick={props.onRequestClose}>
            <Icon name='modalClose' />
          </button>
        </div>
      </div>
    </ReactModal>
  )
}

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func,
  small: PropTypes.bool
}

export default Modal
