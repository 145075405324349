import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { VelocityComponent } from 'velocity-react'
import { connect } from 'react-redux'
import { Link } from 'gatsby'
import Icon from '../Icon/Icon'
import DropdownAnimation from '../DropdownAnimation/DropdownAnimation'
import styles from './MobileLangNav.module.scss'
import data from './MobileLangNav.json'

const getCurrentLangName = locale => {
  const lang = data.langs.find(l => {
    return l.locale === locale
  })
  return lang.text
}

const DefaultMobileLangNav = props => {
  const locale = props.locale
  const translations = props.translations
  return (
    <div className={styles.wrap}>
      <div className={styles.container}>
        <div className={styles.icon}>
          <Icon name='globe' />
        </div>
        <VelocityComponent
          animation={{
            opacity: props.isVisible ? 0 : 1
          }}
          duration={300}
          display={props.isVisible ? 'none' : 'block'}
        >
          <button
            type='button'
            onClick={() => {
              props.showLangNav()
            }}
            className={styles.button}
          >
            {getCurrentLangName(locale)}
          </button>
        </VelocityComponent>
        <DropdownAnimation
          animation={props.isVisible ? 'slideDown' : 'slideUp'}
          easing='easeInOutSine'
          duration={300}
          delay={props.isVisible ? 0 : 300}
        >
          <div className={styles.listContainer}>
            <VelocityComponent
              animation={{
                opacity: props.isVisible ? 1 : 0
              }}
              duration={300}
              delay={props.isVisible ? 300 : 0}
            >
              <ul className={styles.list}>
                {translations.map((item, i) => {
                  return (
                    <li key={i}>
                      <Link
                        to={item.path.replace('/grau-roig/', '/')}
                        activeClassName={styles.active}
                      >
                        {item.native_name}
                      </Link>
                    </li>
                  )
                })}
              </ul>
            </VelocityComponent>
          </div>
        </DropdownAnimation>
      </div>
    </div>
  )
}

DefaultMobileLangNav.propTypes = {
  locale: PropTypes.string.isRequired,
  translations: PropTypes.array.isRequired
}

const mapStateToProps = ({ langData }) => {
  return { langData }
}

const MobileLangNav = connect(mapStateToProps)(DefaultMobileLangNav)

export default MobileLangNav
