import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DropdownAnimation from '../DropdownAnimation/DropdownAnimation'
import { VelocityComponent } from 'velocity-react'
import Icon from '../Icon/Icon'
import Modal from '../Modal/Modal'
import styles from './BookFormField.module.scss'

const InnerChild = props => {
  const label = props.childHasLabel
    ? <div className={styles.childLabel}>{props.label}</div>
    : null
  return (
    <div>
      {label}
      {props.children}
    </div>
  )
}

const FadeContainer = props => {
  const childrenModifier = props.pinned ? styles.bottom : styles.top
  return (
    <div>
      <VelocityComponent
        animation={props.childVisible ? 'fadeIn' : 'fadeOut'}
      >
        <div className={`${styles.children} ${childrenModifier}`}>
          <InnerChild label={props.label} childHasLabel={props.childHasLabel}>
            {props.children}
          </InnerChild>
        </div>
      </VelocityComponent>
    </div>
  )
}

const DropdownContainer = props => {
  return (
    <div>
      <DropdownAnimation
        animation={props.childVisible ? 'slideDown' : 'slideUp'}
        easing='easeInOutSine'
        duration={300}
        delay={props.childVisible ? 0 : 300}
      >
        <div className={styles.childrenDropdown}>
          <VelocityComponent
            animation={{
              opacity: props.childVisible ? 1 : 0
            }}
            duration={300}
            delay={props.childVisible ? 300 : 0}
          >
            <div className={styles.childrenFade}>
              <InnerChild label={props.label} childHasLabel={props.childHasLabel}>
                {props.children}
              </InnerChild>
            </div>
          </VelocityComponent>
        </div>
      </DropdownAnimation>
    </div>
  )
}

const ModalContainer = props => {
  return (
    <div>
      <Modal
        isOpen={props.childVisible}
        onRequestClose={props.handleClose}
        small
      >
        <InnerChild label={props.label} childHasLabel={props.childHasLabel}>
          {props.children}
        </InnerChild>
      </Modal>
    </div>
  )
}

const BookFormField = props => {
  const fieldModifier = props.beta ? styles.beta : ''
  let childrenContainer = null
  if (props.dropdown) {
    childrenContainer = (
      <DropdownContainer
        childVisible={props.childVisible}
        label={props.label}
        childHasLabel={props.childHasLabel}
      >
        {props.children}
      </DropdownContainer>
    )
  } else if (props.desktopModal) {
    childrenContainer = (
      <ModalContainer
        childVisible={props.childVisible}
        label={props.label}
        childHasLabel={props.childHasLabel}
        handleClose={() => { props.handleClick() }}
      >
        {props.children}
      </ModalContainer>
    )
  } else {
    childrenContainer = (
      <FadeContainer
        pinned={props.pinned}
        childVisible={props.childVisible}
        label={props.label}
        childHasLabel={props.childHasLabel}
      >
        {props.children}
      </FadeContainer>
    )
  }
  return (
    <div className={`${styles.field} ${fieldModifier}`}>
      <div role='button' className={styles.button} onClick={props.handleClick}>
        <div className={styles.label}>
          {props.label}
        </div>
        <div className={styles.value}>
          {props.value}
          <div className={styles.icon}>
            <Icon name='grayLightCaret' />
          </div>
        </div>
      </div>
      {childrenContainer}
      <div
        style={{display: props.childVisible ? 'block' : 'none'}}
        className={styles.overlay}
        onClick={props.handleClick}
      />
    </div>
  )
}

BookFormField.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  pinned: PropTypes.bool.isRequired,
  childVisible: PropTypes.bool.isRequired,
  childHasLabel: PropTypes.bool
}

export default BookFormField
