import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../Icon/Icon'
import styles from './PswpButtonOverlay.module.scss'
import tr from './PswpButtonOverlay.json'

const PswpButtonOverlay = props => (
  <div className={styles.overlay}>
    <div className={styles.label}>
      <span className={styles.text}>
        {tr.label.text[props.locale]}
      </span>
      <div className={styles.icon}>
        <Icon name='galleryOpen' />
      </div>
    </div>
  </div>
)

PswpButtonOverlay.propTypes = {
  locale: PropTypes.string.isRequired
}

export default PswpButtonOverlay
