import React from 'react'
import PropTypes from 'prop-types'
import ImageSlider from '../ImageSlider/ImageSlider'
import SmallWysiwyg from '../SmallWysiwyg/SmallWysiwyg'
import styles from './ActivityContent.module.scss'

const ActivityContent = props => {
  let gallery = null
  let description = null
  if (props.gallery) {
    gallery = (
      <div className={styles.gallery}>
        <ImageSlider images={props.gallery} />
      </div>
    )
  }
  if (props.description) {
    description = (
      <div className={styles.description}>
        <SmallWysiwyg content={props.description} />
      </div>
    )
  }

  return (
    <div className={styles.wrap}>
      <div className={styles.parentTitle}>{props.parentTitle}</div>
      <h2 className={styles.title}>{props.title}</h2>
      {gallery}
      {description}
      <div className={styles.book}>
        <h3 className={styles.bookTitle}>{props.contact.title}</h3>
        <ul className={styles.bookList}>
          {props.contact.contact_list.map((item, i) => {
            return (
              <li key={i}>
                <a href={item.link}>{item.text}</a>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

ActivityContent.propTypes = {
  parentTitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  gallery: PropTypes.array,
  description: PropTypes.string,
  book: PropTypes.object,
  contact: PropTypes.object.isRequired
}

export default ActivityContent
