import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql, Link } from 'gatsby'
import SectionTitle from '../SectionTitle/SectionTitle'
import Icon from '../Icon/Icon'
import LinkedPosts from '../LinkedPosts/LinkedPosts'
import styles from './HomeLinkedPosts.module.scss'

const tr = {
  viewAll: {
    es: 'Ver todo',
    ca: 'Veure tot',
    en: 'View all',
    fr: 'Voir tout'
  }
}

/**
 * @param {Array} edges
 * @param {String} locale
 */
function getCurrentLocaleEdges (edges, locale) {
  return edges.filter(({ node }) => {
    return node.wpml_language_information.language_code === locale
  })
}

const HomeLinkedPosts = ({ locale }) => {
  return (
    <StaticQuery
      query={graphql`
        query HomeLinkedPostsQuery {
          posts: allWordpressPost {
            edges {
              node {
                id
                path
                title
                categories {
                  id
                  name
                }
                acf {
                  subtitle
                }
                wpml_language_information {
                  language_code
                }
              }
            }
          }
          blogPages: allWordpressPage(
            filter: { template: { eq: "page-templates/Blog.php" } }
          ) {
            edges {
              node {
                path
                title
                wpml_language_information {
                  language_code
                }
              }
            }
          }
        }
      `}
      render={data => {
        const allPosts = getCurrentLocaleEdges(data.posts.edges, locale)

        if (allPosts.length === 0) return null

        const posts = allPosts.slice(0, 3)
        const blogPagePath = getCurrentLocaleEdges(
          data.blogPages.edges,
          locale
        )[0].node.path
        const blogPageTitle = getCurrentLocaleEdges(
          data.blogPages.edges,
          locale
        )[0].node.title
        return (
          <div className={styles.container}>
            {/* <SectionTitle text={tr.lastPosts[locale]} /> */}
            <div className={styles.header}>
              <div className={styles.headerTitle}>
                <Link to={blogPagePath}>{blogPageTitle}</Link>
              </div>
              <Link to={blogPagePath} className={styles.headerLink}>
                {tr.viewAll[locale]}
                <Icon name='smallArrowRightGold' />
              </Link>
            </div>
            <LinkedPosts edges={posts} noImages />
            {/* <div className={styles.footer}>
              <Link to='/blog/' className={styles.headerLink}>
                {tr.viewAll[locale]}
                <Icon name='smallArrowRightGold' />
              </Link>
            </div> */}
            {/* <div className={styles.cta}>
              <Link to='/blog/' className={styles.ctaLink}>
                {tr.viewAll[locale]}
              </Link>
            </div> */}
          </div>
        )
      }}
    />
  )
}

HomeLinkedPosts.propTypes = {
  locale: PropTypes.string.isRequired
}

export default HomeLinkedPosts
