import React, { Component, Fragment } from 'react'
import { graphql } from 'gatsby'
import { MediaQueryProvider, MediaQuery } from 'react-responsive-hoc'
import { connect } from 'react-redux'
import MobileHeader from '../components/MobileHeader/MobileHeader'
import ConnectedDesktopHeader from '../components/DesktopHeader/DesktopHeader'
import FixedBook from '../components/FixedBook/FixedBook'
import MainFooter from '../components/MainFooter/MainFooter'
import '../styles/styles'

// https://github.com/jordan-enev/react-velocity-scroll
const Velocity = require('../../node_modules/velocity-react/lib/velocity-animate-shim')

const scrollAnimation = id => {
  const el = document.querySelector(id)
  Velocity(el, 'scroll', {
    duration: 2000,
    easing: 'easeOutQuart',
    mobileHA: false,
    offset: -80
  })
}

class DefaultTemplateWrapper extends Component {
  componentDidMount () {
    document.documentElement.classList.add('js')

    if (this.props.scrollTarget) {
      setTimeout(() => {
        scrollAnimation(this.props.scrollTarget)
      }, 600)
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.scrollTarget !== this.props.scrollTarget) {
      scrollAnimation(this.props.scrollTarget)
    }
  }

  render () {
    const locale = this.props.locale
    const navMenuLeft = this.props.mainHeaderData.nav_menu_left
    const navMenuRight = this.props.mainHeaderData.nav_menu_right
    const mainNavButtonLabel = navMenuLeft.main_nav_button.label
    const megamenu = this.props.mainHeaderData.megamenu
    const bookContent = this.props.bookContent
    const bookFormHeader = {
      label: bookContent.room.dropdown.label,
      title: bookContent.room.dropdown.title
    }
    const bookFormLabels =
      bookContent.room.dropdown.content.book_form.book_form_labels
    const locationKey = this.props.locationKey
    const locationPageLink = navMenuRight.nav.items.find(
      item => item.attr.includes('location-icon')
    )
    const phoneLink = navMenuRight.nav.items.find(
      item => item.attr.includes('phone-link')
    )
    const whatsappLink = navMenuRight.nav.items.find(
      item => item.attr.includes('icon-whatsapp')
    )
    const locationPageUrl = locationPageLink.url.replace(
      /https?:\/\/[^\/]+/i,
      ''
    )
    const footerLegalNav = this.props.mainHeaderData.footer_menu.nav.items
    const contactSections = megamenu.contact.sections
    const whatsapp = megamenu.contact.whatsapp
    const mobileBetaNav = this.props.mainHeaderData.mobile_contact_menu.nav
      .items

    return (
      <Fragment>
        <div>
          <MediaQueryProvider width={375} height={812}>
            <MediaQuery query='(max-width: 1099px)'>
              <MobileHeader
                locale={locale}
                bookFormLabels={bookFormLabels}
                bookFormHeader={bookFormHeader}
                mainNav={megamenu.main_nav.items}
                mainNavButtonLabel={mainNavButtonLabel}
                betaNav={mobileBetaNav}
                phoneLink={phoneLink}
                whatsappLink={whatsappLink}
                locationKey={locationKey}
                locationPageUrl={locationPageUrl}
                translations={this.props.translations}
              />
            </MediaQuery>
          </MediaQueryProvider>
        </div>
        <div>
          <MediaQueryProvider width={1440} height={900}>
            <MediaQuery query='(min-width: 1100px)'>
              <ConnectedDesktopHeader
                locale={locale}
                navMenuLeft={navMenuLeft}
                navMenuRight={navMenuRight}
                megamenu={megamenu}
                bookContent={bookContent}
                locationKey={locationKey}
                translations={this.props.translations}
              />
            </MediaQuery>
          </MediaQueryProvider>
        </div>
        <div id='content'>{this.props.children}</div>
        <div>
          <MediaQueryProvider width={375} height={812}>
            <MediaQuery query='(max-width: 1099px)'>
              <FixedBook
                header={bookFormHeader}
                locale={locale}
                labels={bookFormLabels}
              />
            </MediaQuery>
          </MediaQueryProvider>
        </div>
        <MainFooter
          locale={locale}
          legalNav={footerLegalNav}
          contactSections={contactSections}
          whatsapp={whatsapp}
        />
      </Fragment>
    )
  }
}

DefaultTemplateWrapper.propTypes = {
  // children: PropTypes.func
}

const mapStateToProps = ({ langData, scrollTarget }) => {
  return { langData, scrollTarget }
}

const TemplateWrapper = connect(mapStateToProps)(DefaultTemplateWrapper)

export default TemplateWrapper

export const query = graphql`
  fragment CroppedSquareImageFiles on wordpress__wp_media {
    localFile {
      childImageSharp {
        croppedSizes: fluid(maxWidth: 600, maxHeight: 600) {
          ...GatsbyImageSharpFluid_noBase64
        }
        croppedSizesDuotone: fluid(
          maxWidth: 600
          maxHeight: 600
          duotone: { highlight: "#9C7446", shadow: "#292426" }
        ) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }

  fragment CroppedHorImageFiles on wordpress__wp_media {
    localFile {
      childImageSharp {
        croppedSizes: fluid(maxWidth: 600, maxHeight: 420, quality: 75) {
          ...GatsbyImageSharpFluid_noBase64
        }
        croppedSizesDuotone: fluid(
          maxWidth: 600
          maxHeight: 420
          duotone: { highlight: "#9C7446", shadow: "#292426" }
        ) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }

  fragment CroppedHorThreeByFourImageFiles on wordpress__wp_media {
    localFile {
      childImageSharp {
        croppedSizes: fluid(maxWidth: 600, maxHeight: 450) {
          ...GatsbyImageSharpFluid_noBase64
        }
        croppedSizesDuotone: fluid(
          maxWidth: 600
          maxHeight: 450
          duotone: { highlight: "#9C7446", shadow: "#292426" }
        ) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }

  fragment WordpressPostContent on wordpress__POST {
    id
    path
    title
    date(formatString: "DD MMMM YYYY", locale: $locale)
    content
    categories {
      id
      name
      slug
      parentElement: parent_element {
        id
      }
    }
    acf {
      subtitle
      lead
      featuredMedia: featured_media {
        altText: alt_text
        sourceUrl: source_url
        ...CroppedHorThreeByFourImageFiles
      }
      isFeatured: is_featured
    }
  }

  fragment MainHeaderContent on wordpress___main_header {
    id
    language_code
    nav_menu_left {
      main_nav_button {
        label
      }
      nav {
        slug
        items {
          title
          attr
          object_slug
          url
        }
      }
    }
    nav_menu_right {
      nav {
        slug
        items {
          title
          attr
          object_slug
          url
        }
      }
    }
    megamenu {
      main_nav {
        slug
        items {
          title
          url
          attr
          wordpress_children {
            title
            url
            attr
            classes
          }
        }
      }
      linked_rooms {
        wordpress_id
        slug
        link
        path
        short_title
        linked_image {
          alt_text
          source_url
          ...CroppedSquareImageFiles
        }
      }
      linked_restaurants {
        wordpress_id
        slug
        link
        path
        short_title
        linked_image {
          alt_text
          source_url
          ...CroppedHorThreeByFourImageFiles
        }
      }
      book {
        restaurant {
          button {
            label
            type
            alpha
          }
          dropdown {
            label
            title
            content {
              book_contact {
                addendumm
                list {
                  link
                  text
                }
              }
            }
            image {
              alt_text
              source_url
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
        }
        room {
          button {
            label
            type
            alpha
          }
          dropdown {
            label
            title
            content {
              book_form {
                book_form_labels {
                  arrival
                  departure
                  adults
                  wordpress_children
                  book
                }
              }
            }
            image {
              alt_text
              source_url
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
        }
      }
      contact {
        sections {
          title
          google_maps_link
          location_list
          contact_list {
            link
            text
          }
        }
        contact_page_path
        whatsapp
      }
    }
    footer_menu {
      nav {
        items {
          title
          url
        }
      }
    }
    mobile_contact_menu {
      nav {
        items {
          title
          url
        }
      }
    }
  }
`
