import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import styles from './HomePillars.module.scss'

const HomePillars = props => (
  <div className={styles.wrap}>
    <div className={styles.container}>
      <div className={styles.grid}>
        {props.items.map(item => {
          const backupSourceUrl = item.linked_image.source_url
          const imgAltText = item.linked_image.alt_text
          const localFile = item.linked_image.localFile
          const sizes = localFile
            ? localFile.childImageSharp.croppedSizes
            : null
          const duotoneSizes = localFile
            ? localFile.childImageSharp.croppedSizesDuotone
            : null
          const title = item.short_title
          return (
            <div key={item.wordpress_id} className={styles.item}>
              <Link to={item.path}>
                <div className={styles.image}>
                  {sizes && <Img fluid={sizes} alt={imgAltText} />}
                  {!sizes && (
                    <div className={styles.imageBackup}>
                      <img src={backupSourceUrl} alt={imgAltText} />
                    </div>
                  )}
                  <div className={styles.duotoneImage}>
                    {duotoneSizes && (
                      <Img fluid={duotoneSizes} alt={imgAltText} />
                    )}
                  </div>
                </div>
                <div className={styles.title}>
                  <span>{title}</span>
                </div>
              </Link>
            </div>
          )
        })}
      </div>
    </div>
  </div>
)

HomePillars.propTypes = {
  items: PropTypes.array.isRequired
}

export default HomePillars
