import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import styles from './FeaturedPage.module.scss'
import tr from './FeaturedPage.json'
import { Link } from 'gatsby'

const FeaturedPage = props => {
  const backupSourceUrl = props.image.source_url
  const imgAltText = props.image.alt_text
  const localFile = props.image.localFile
  const sizes = localFile ? localFile.childImageSharp.croppedSizes : null
  const duotoneSizes = localFile
    ? localFile.childImageSharp.croppedSizesDuotone
    : null
  return (
    <div className={styles.wrap}>
      <Link to={props.path}>
        <div className={styles.grid}>
          <div className={styles.imageColumn}>
            <div className={styles.image}>
              {sizes && <Img fluid={sizes} alt={imgAltText} />}
              {!sizes && (
                <div className={styles.imageBackup}>
                  <img src={backupSourceUrl} alt={imgAltText} />
                </div>
              )}
              <div className={styles.duotoneImage}>
                {duotoneSizes && <Img fluid={duotoneSizes} alt={imgAltText} />}
              </div>
            </div>
          </div>
          <div className={styles.infoColumn}>
            <div className={styles.infoGrid}>
              <div className={styles.infoGridColLeft}>
                <div className={styles.title}>
                  <span>{props.title}</span>
                </div>
              </div>
              <div className={styles.infoGridColRight}>
                <div
                  className={styles.description}
                  dangerouslySetInnerHTML={{ __html: props.description }}
                />
                <div className={styles.button}>
                  <span>{tr.button[props.locale]}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}

FeaturedPage.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  path: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired
}

export default FeaturedPage
