import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import SectionTitle from '../SectionTitle/SectionTitle'
import Icon from '../Icon/Icon'
import styles from './LinkedRestaurants.module.scss'
import tr from './LinkedRestaurants.json'

class LinkedRestaurants extends Component {
  render () {
    let sectionTitle = null
    if (!this.props.noTitle) {
      sectionTitle = <SectionTitle text={tr.title[this.props.locale]} />
    }
    let modifier = ''
    if (this.props.noSpacing) {
      modifier = styles.noSpacing
    }
    let widthModifier = ''
    if (this.props.items.length === 3) {
      widthModifier = styles.oneThird
    } else if (this.props.items.length === 4) {
      widthModifier = styles.oneQuarter
    } else if (this.props.items.length === 5) {
      widthModifier = styles.oneFifth
    } else if (this.props.items.length === 6) {
      widthModifier = styles.oneSixth
    }

    return (
      <div className={`${styles.wrap} ${modifier}`}>
        {sectionTitle}
        <div className={styles.container}>
          <div className={styles.grid}>
            {this.props.items.map(item => {
              const backupSourceUrl = item.linked_image.source_url
              const imgAltText = item.linked_image.alt_text
              const localFile = item.linked_image.localFile
              const sizes = localFile
                ? localFile.childImageSharp.croppedSizes
                : null
              const duotoneSizes = localFile
                ? localFile.childImageSharp.croppedSizesDuotone
                : null
              const title = item.short_title
              return (
                <div
                  key={item.wordpress_id}
                  className={`${styles.item} ${widthModifier}`}
                >
                  <Link to={item.path}>
                    <div className={styles.image}>
                      {sizes && <Img fluid={sizes} alt={imgAltText} />}
                      {!sizes && (
                        <div className={styles.imageBackup}>
                          <img src={backupSourceUrl} alt={imgAltText} />
                        </div>
                      )}
                      <div className={styles.duotoneImage}>
                        {duotoneSizes && (
                          <Img fluid={duotoneSizes} alt={imgAltText} />
                        )}
                      </div>
                    </div>
                    <div className={styles.titleGrid}>
                      <div className={styles.title}>
                        <span>{title}</span>
                      </div>
                      <div className={styles.icon}>
                        <Icon name='smallArrowRightGold' />
                      </div>
                    </div>
                  </Link>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  }
}

LinkedRestaurants.propTypes = {
  items: PropTypes.array.isRequired,
  locale: PropTypes.string,
  noTitle: PropTypes.bool,
  noSpacing: PropTypes.bool
}

export default LinkedRestaurants
