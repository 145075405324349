import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { MediaQueryProvider, MediaQuery } from 'react-responsive-hoc'
import Img from 'gatsby-image'
import Modal from '../Modal/Modal'
import styles from './BookRestaurant.module.scss'

class BookRestaurant extends Component {
  constructor (props) {
    super(props)
    this.state = {
      showModal: false
    }
  }

  toggleModal () {
    if (this.state.showModal) {
      this.setState({
        showModal: false
      })
    } else {
      this.setState({
        showModal: true
      })
    }
  }

  closeModal () {
    this.setState({
      showModal: false
    })
  }

  render () {
    const backupSourceUrl = this.props.data.image.source_url
    const imgAltText = this.props.data.image.alt_text
    const localFile = this.props.data.image.localFile
    const fixed = localFile ? localFile.childImageSharp.fixed : null
    const title = this.props.data.title
    const description = this.props.data.description
    const buttonText = this.props.data.button_text
    return (
      <div className={styles.wrap}>
        <MediaQueryProvider width={1440} height={900}>
          <MediaQuery query='(min-width: 489px)'>
            <div className={styles.image}>
              {localFile &&
                <Img fixed={fixed} alt={imgAltText} />
              }
              {!localFile &&
                <div className={styles.imageBackup}>
                  <img src={backupSourceUrl} alt={imgAltText} />
                </div>
              }
            </div>
          </MediaQuery>
        </MediaQueryProvider>
        <div className={styles.info}>
          <h2 className={styles.title} dangerouslySetInnerHTML={{ __html: title }} />
          <div className={styles.description} dangerouslySetInnerHTML={{ __html: description }} />
          <div className={styles.buttonContainer}>
            <button
              className={styles.button}
              onClick={() => { this.toggleModal() }}
            >
              {buttonText}
            </button>
          </div>
        </div>
        <Modal
          isOpen={this.state.showModal}
          onRequestClose={() => { this.closeModal() }}
        >
          <div className={styles.book}>
            <h3 className={styles.bookTitle}>{this.props.contact.title}</h3>
            <ul className={styles.bookList}>
              {this.props.contact.list.map((item, i) => {
                return (
                  <li key={i}>
                    <a href={item.link}>{item.text}</a>
                  </li>
                )
              })}
            </ul>
            <div
              className={styles.bookAddendum}
              dangerouslySetInnerHTML={{ __html: this.props.contact.addendum}}
            />
          </div>
        </Modal>
      </div>
    )
  }
}

BookRestaurant.propTypes = {
  data: PropTypes.object.isRequired,
  contact: PropTypes.object.isRequired
}

export default BookRestaurant
