import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Icon from '../Icon/Icon'
import AnchorScrollButton from '../AnchorScrollButton/AnchorScrollButton'
import styles from './DesktopMainNav.module.scss'
import MainNavBlog from '../MainNavBlog/MainNavBlog'

const DesktopMainNav = ({ items, locationKey, locale }) => {
  let tempItems = items
  return (
    <div className={styles.wrap}>
      <div className={styles.gridContainer}>
        <div className={styles.grid}>
          {tempItems.map((item, i) => {
            const beforePromo =
              i < tempItems.length - 1 &&
              tempItems[i + 1].attr === 'promo-column'
            return (
              <Column
                key={i}
                item={item}
                locationKey={locationKey}
                beforePromo={beforePromo}
              />
            )
          })}
          <MainNavBlog locale={locale} />
        </div>
      </div>
    </div>
  )
}

const Column = props => {
  let modifier = ''
  if (props.item.attr === 'promo-column') {
    // modifier = styles.promo
  } else if (props.beforePromo) {
    // modifier = styles.beforePromo
  }
  const list = props.item.wordpress_children ? (
    <List
      data={props.item.wordpress_children}
      locationKey={props.locationKey}
    />
  ) : null
  return (
    <div className={`${styles.column} ${modifier}`}>
      <div className={`${styles.innerColumn} ${styles.textColumn}`}>
        <AlphaLink
          text={props.item.title}
          url={props.item.url}
          attr={props.item.attr}
        />
        {list}
      </div>
    </div>
  )
}

const AlphaLink = props => {
  let content = null
  if (props.attr === 'no-link') {
    content = (
      <div className={styles.alphaGrid}>
        <div className={styles.alphaText}>{props.text}</div>
      </div>
    )
  } else {
    const url = props.url.replace(/https?:\/\/[^\/]+/i, '')
    content = (
      <Link to={url} activeClassName={styles.active}>
        <div className={styles.alphaGrid}>
          <div className={styles.alphaText}>{props.text}</div>
          <div className={styles.alphaIcon}>
            <Icon name='smallArrowRightGold' />
          </div>
        </div>
      </Link>
    )
  }
  return <div className={styles.alpha}>{content}</div>
}

const List = props => (
  <ul className={styles.list}>
    {props.data.map((child, i) => {
      const url = child.url.replace(/https?:\/\/[^\/]+/i, '')
      let content = null
      if (child.attr.indexOf('#') !== -1) {
        content = (
          <AnchorScrollButton
            title={child.title}
            url={url}
            attr={child.attr}
            locationKey={props.locationKey}
          />
        )
      } else {
        content = (
          <Link to={url} activeClassName={styles.active}>
            {child.title}
          </Link>
        )
      }
      return <li key={i}>{content}</li>
    })}
  </ul>
)

DesktopMainNav.propTypes = {
  locale: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  locationKey: PropTypes.string.isRequired
}

export default DesktopMainNav
