import React from 'react'
import PropTypes from 'prop-types'
import ReactModal from 'react-modal'
import styles from './Pswp.module.scss'
import { PhotoSwipe } from 'react-photoswipe'
import './defaultStyles.scss'
import './customStyles.scss'

// http://photoswipe.com/documentation/options.html
const convertDimensions = (width, height) => {
  let obj = {}
  const FIXED_WIDTH = 1920
  const pct = (FIXED_WIDTH / width) * 100
  const h = (height * pct) / 100
  obj.w = FIXED_WIDTH
  obj.h = h
  return obj
}

const pswpOptions = {
  showHideOpacity: true,
  showAnimationDuration: 500,
  timeToIdle: 3000,
  shareEl: false,
  preloaderEl: false,
  closeOnVerticalDrag: false,
  closeOnScroll: false,
  captionEl: true,
  fullscreenEl: false,
  zoomEl: false,
  history: false,
  barsSize: { top: 80, bottom: 80 }
}

const Pswp = props => {
  let items = []
  props.images.forEach((image) => {
    const dimensions = convertDimensions(image.media_details.width, image.media_details.height)
    let obj = {}
    const sourceUrl = image.source_url
    const localFile = image.localFile
    const src = localFile ? localFile.childImageSharp.fluid.src : sourceUrl
    obj.src = src
    obj.w = dimensions.w
    obj.h = dimensions.h
    if (image.caption) obj.title = image.caption
    if (src) {
      items.push(obj)
    }
  })
  const options = {
    ...pswpOptions,
    index: props.current
  }
  return (
    <div>
      <ReactModal
        isOpen={props.isVisible}
        className={styles.modal}
        overlayClassName={styles.overlay}
        onRequestClose={() => { props.onVisibilityChange(false, props.current) }}
        ariaHideApp={false}
      >
        <PhotoSwipe
          isOpen={props.isVisible}
          onClose={() => { props.onVisibilityChange(false, props.current) }}
          items={items}
          options={options}
        />
      </ReactModal>
    </div>
  )
}

Pswp.propTypes = {
  images: PropTypes.array.isRequired,
  current: PropTypes.number.isRequired,
  isVisible: PropTypes.bool.isRequired
}

export default Pswp
