import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactHoverObserver from 'react-hover-observer'
import MainNavButton from '../MainNavButton/MainNavButton'

class Button extends Component {
  componentWillUpdate (nextProps) {
    const diffProps = this.props.isHovering !== nextProps.isHovering
    if (diffProps) {
      const data = {
        active: nextProps.isHovering,
        megamenu: {
          attr: 'main-menu',
          type: 'mainNav'
        }
      }
      this.props.handleMegamenu(data)
    }
  }

  // TEMP
  // componentDidMount () {
  //   const data = {
  //     active: true,
  //     megamenu: {
  //       attr: 'main-menu',
  //       type: 'mainNav'
  //     }
  //   }
  //   this.props.handleMegamenu(data)
  // }

  render () {
    return <MainNavButton text={this.props.text} />
  }
}

const MainNavDesktopButton = props => (
  <ReactHoverObserver hoverDelayInMs={150} hoverOffDelayInMs={150}>
    <Button text={props.text} handleMegamenu={props.handleMegamenu} />
  </ReactHoverObserver>
)

MainNavDesktopButton.propTypes = {
  text: PropTypes.string.isRequired,
  handleMegamenu: PropTypes.func.isRequired
}

export default MainNavDesktopButton
