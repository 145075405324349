import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './Alert.module.scss'

class Alert extends Component {
  render() {
    return (
      <div className={`${styles.alert} ${this.props.hasTitle ? styles.hasTitle : ''}`}>
        <div>
          <div>{ this.props.message }</div>
        </div>
      </div>
    )
  }
}

Alert.propTypes = {
  message: PropTypes.string,
}

export default Alert
