import React, { Component } from 'react'
import PropTypes from 'prop-types'
import SectionTitle from '../SectionTitle/SectionTitle'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import styles from './LinkedActivityTypes.module.scss'

class LinkedActivityTypes extends Component {
  render () {
    let modifier = ''
    if (this.props.items.length === 3) {
      modifier = styles.oneThird
    } else if (this.props.items.length === 4) {
      modifier = styles.oneQuarter
    } else if (this.props.items.length === 5) {
      modifier = styles.oneFifth
    }
    return (
      <div className={styles.wrap}>
        <SectionTitle text={this.props.sectionTitle} />
        <div className={styles.container}>
          <div className={styles.grid}>
            {this.props.items.map((item, i) => {
              const backupSourceUrl = item.image.source_url
              const imgAltText = item.image.alt_text
              const localFile = item.image.localFile
              const sizes = localFile
                ? localFile.childImageSharp.croppedSizes
                : null
              const duotoneSizes = localFile
                ? localFile.childImageSharp.croppedSizesDuotone
                : null
              const title = item.short_title
              return (
                <div className={`${styles.item} ${modifier}`} key={i}>
                  <Link to={item.path}>
                    <div className={styles.image}>
                      {sizes && <Img fluid={sizes} alt={imgAltText} />}
                      {!sizes && (
                        <div className={styles.imageBackup}>
                          <img src={backupSourceUrl} alt={imgAltText} />
                        </div>
                      )}
                      <div className={styles.duotoneImage}>
                        {duotoneSizes && (
                          <Img fluid={duotoneSizes} alt={imgAltText} />
                        )}
                      </div>
                    </div>
                    <div className={styles.title}>
                      <span>{title}</span>
                    </div>
                  </Link>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  }
}

LinkedActivityTypes.propTypes = {
  sectionTitle: PropTypes.string,
  items: PropTypes.array.isRequired
}

export default LinkedActivityTypes
