import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Icon from '../Icon/Icon'
import styles from './MainFooter.module.scss'
import Obfuscate from 'react-obfuscate'

const MainFooter = ({ locale, contactSections, legalNav, whatsapp }) => {
  const location = contactSections.find(section => section.location_list)
  const contact = contactSections.find(section => section.contact_list)
  let creditsLink = 'https://domo-a.com/'
  if (locale === 'es') {
    creditsLink = 'https://domo-a.com/es/'
  } else if (locale === 'en') {
    creditsLink = 'https://domo-a.com/en/'
  }
  return (
    <div className={styles.wrap}>
      <div className={styles.container}>
        <div className={styles.brand}>
          <Icon name='mainFooterBrand' />
        </div>
        <ul className={styles.contact}>
          {location.location_list.map((item, i) => {
            return (
              <li key={i}>
                <a
                  href={location.google_maps_link}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {item}
                </a>
              </li>
            )
          })}
        </ul>
        <ul className={styles.contact}>
          {contact.contact_list.map((item, i) => {
            return (
              <li key={i}>
                <a href={item.link}>{item.text}</a>
              </li>
            )
          })}
          {whatsapp &&
            <li><a href={whatsapp}>WhatsApp</a></li>
          }
        </ul>
        <div className={styles.bottom}>
          <div className={styles.copyright}>
            Grau Roig © {new Date().getFullYear()}
          </div>
          <ul className={styles.legal}>
            {legalNav.map((item, i) => {
              const url = item.url.replace(/https?:\/\/[^\/]+/i, '')
              return (
                <li key={i}>
                  <Link to={url}>{item.title}</Link>
                </li>
              )
            })}
          </ul>
          <div className={styles.credits}>
            <Obfuscate
              href={creditsLink}
              target='_blank'
              obfuscateChildren={false}
              rel='nofollow noopener noreferrer'
            >
              Site by DOMO-A
            </Obfuscate>
          </div>
        </div>
      </div>
    </div>
  )
}

MainFooter.propTypes = {
  locale: PropTypes.string.isRequired,
  contactSections: PropTypes.array.isRequired,
  legalNav: PropTypes.array.isRequired,
  whatsapp: PropTypes.string.isRequired,
}

export default MainFooter
