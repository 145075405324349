import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Swiper from 'react-id-swiper'
import Img from 'gatsby-image'
import styles from './ImageSlider.module.scss'
import './swiper.scss'
import './custom.scss'

const swiperConfig = {
  autoHeight: true,
  spaceBetween: 1,
  watchOverflow: true,
  pagination: {
    el: '.swiper-custom-pagination',
    renderBullet: function(index, className) {
      return `<span class="${className}">${index + 1}</span>`
    },
    clickable: true
  },
  navigation: {
    nextEl: '.swiper-custom-button-next'
  }
}

const ImageSlider = props => (
  <div className={styles.wrap}>
    <div className={styles.items}>
      <Swiper {...swiperConfig}>
        {props.images.map((image, i) => {
          const backupSourceUrl = image.source_url
          const imgAltText = image.alt_text
          const localFile = image.localFile
          const sizes = localFile ? localFile.childImageSharp.fluid : null
          return (
            <div key={i} className={styles.item}>
              {sizes && <Img fluid={sizes} alt={imgAltText} />}
              {!sizes && (
                <div className={styles.imageBackup}>
                  <img src={backupSourceUrl} alt={imgAltText} />
                </div>
              )}
            </div>
          )
        })}
      </Swiper>
    </div>
  </div>
)

ImageSlider.propTypes = {
  images: PropTypes.array.isRequired
}

export default ImageSlider
