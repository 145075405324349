import React from 'react'
import PropTypes from 'prop-types'
import PswpButtonOverlay from '../PswpButtonOverlay/PswpButtonOverlay'
import Image from '../Image/Image'
import styles from './ImageGallery.module.scss'

const ImageGallery = props => (
  <div className={styles.wrap}>
    {props.data.map(({row}, i) => {
      let rowContent = null

      if (row.layout) {
        // Single image
        const layout = row.layout
        let modifier
        if (layout === 'center_one_whole') {
          modifier = styles.centerOneWhole
        } else if (layout === 'center_half') {
          modifier = styles.centerHalf
        } else if (layout === 'center_two_third') {
          modifier = styles.centerTwoThird
        } else if (layout === 'center_three_fifth') {
          modifier = styles.centerThreeFifth
        } else if (layout === 'center_five_sixth') {
          modifier = styles.centerFiveSixth
        }
        const backupSourceUrl = row.image.source_url
        const imgAltText = row.image.alt_text
        const localFile = row.image.localFile
        const fluid = localFile ? localFile.childImageSharp.fluid : null
        rowContent = (
          <div className={`${styles.row} ${modifier}`} key={i}>
            <ImageElement
              handleClick={() => props.openPswpById(row.image.id)}
              locale={props.locale}
              backupSourceUrl={backupSourceUrl}
              fluid={fluid}
              title={row.image.title}
              altText={imgAltText}
              caption={row.caption}
            />
          </div>
        )
      } else if (row.widths) {
        // Image grid
        const widths = row.widths
        let leftWidth
        let rightWidth
        if (widths === 'halves') {
          leftWidth = styles.oneHalf
          rightWidth = styles.oneHalf
        } else if (widths === 'one_two_third') {
          leftWidth = styles.oneThird
          rightWidth = styles.twoThird
        } else if (widths === 'two_one_third') {
          leftWidth = styles.twoThird
          rightWidth = styles.oneThird
        }
        const leftBackupSourceUrl = row.left.source_url
        const leftImgAltText = row.left.alt_text
        const leftLocalFile = row.left.localFile
        const leftFluid = leftLocalFile ? leftLocalFile.childImageSharp.fluid : null
        const rightBackupSourceUrl = row.right.source_url
        const rightImgAltText = row.right.alt_text
        const rightLocalFile = row.right.localFile
        const rightFluid = rightLocalFile ? rightLocalFile.childImageSharp.fluid : null
        rowContent = (
          <div className={styles.grid} key={i}>
            <div className={`${styles.item} ${leftWidth}`}>
              <ImageElement
                handleClick={() => props.openPswpById(row.left.id)}
                locale={props.locale}
                backupSourceUrl={leftBackupSourceUrl}
                fluid={leftFluid}
                title={row.left.title}
                altText={leftImgAltText}
                caption={row.caption_left}
              />
            </div>
            <div className={`${styles.item} ${rightWidth}`}>
              <ImageElement
                handleClick={() => props.openPswpById(row.right.id)}
                locale={props.locale}
                backupSourceUrl={rightBackupSourceUrl}
                fluid={rightFluid}
                title={row.right.title}
                altText={rightImgAltText}
                caption={row.caption_right}
              />
            </div>
          </div>
        )
      }

      return rowContent
    })}
  </div>
)

const ImageElement = props => {
  const caption = props.caption
    ? <div className={styles.caption}>{props.caption}</div>
    : null
  return (
    <div className={styles.image}>
      <div
        className={styles.pswpButton}
        role='button'
        onClick={() => { props.handleClick() }}
      >
        <PswpButtonOverlay locale={props.locale} />
        {props.fluid &&
          <Image
            fluid={props.fluid}
            imageProps={{
              title: props.title,
              alt: props.altText
            }}
          />
        }
        {!props.fluid &&
          <img
            className={styles.backupImage}
            src={props.backupSourceUrl}
            alt={props.altText}
          />
        }
        {caption}
      </div>
    </div>
  )
}

ImageGallery.propTypes = {
  data: PropTypes.array.isRequired,
  locale: PropTypes.string.isRequired,
  openPswpById: PropTypes.func
}

export default ImageGallery
