import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './BookContact.module.scss'

class BookContact extends Component {
  render () {
    return (
      <div className={styles.contact}>
        <ul className={styles.list}>
          {this.props.list.map((item, i) => {
            return (
              <li key={i}>
                <a href={item.link}>{item.text}</a>
              </li>
            )
          })}
        </ul>
        <div
          className={styles.addendum}
          dangerouslySetInnerHTML={{ __html: this.props.addendum}}
        />
      </div>
    )
  }
}

BookContact.propTypes = {
  list: PropTypes.array.isRequired,
  addendum: PropTypes.string.isRequired
}

export default BookContact
