import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withPrefix } from 'gatsby-link'
import Icon from '../Icon/Icon'
import styles from './ContactGrid.module.scss'

const ContactGrid = ({ data }) => (
  <div className={styles.wrap}>
    <div className={styles.container}>
      <div className={styles.grid}>
        <div className={styles.column}>
          <a href={data.sections[0].google_maps_link}>
            <div className={styles.map}>
              <img src={withPrefix('/mapaLocalitzacio.svg')} />
              {/* <div className={styles.button}>
                <div className={styles.buttonText}>
                  <span>{data.button.text}</span>
                </div>
              </div> */}
            </div>
          </a>
        </div>
        <div className={styles.column}>
          {data.sections.map((section, i) => {
            let contactList = null
            if (section.contact_list) {
              contactList = (
                <ul className={styles.list}>
                  {section.contact_list.map(({ link, text }, j) => {
                    return (
                      <li key={j}>
                        <a href={link}>{text}</a>
                      </li>
                    )
                  })}
                </ul>
              )
            }
            let locationList = null
            if (section.location_list) {
              locationList = (
                <a href={section.google_maps_link} target='_blank' rel='noopener noreferrer'>
                  <ul className={styles.list}>
                    {section.location_list.map((item, j) => {
                      return (
                        <li key={j}>
                          {item}
                        </li>
                      )
                    })}
                  </ul>
                </a>
              )
            }
            return (
              <div key={i} className={styles.section}>
                <h2 className={styles.title}>{section.title}</h2>
                {contactList}
                {locationList}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  </div>
)

ContactGrid.propTypes = {
  data: PropTypes.object.isRequired
}

export default ContactGrid
