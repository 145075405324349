import React from 'react'
import PropTypes from 'prop-types'
import styles from './MegamenuContact.module.scss'

const MegamenuContact = ({ data }) => (
  <div className={styles.wrap}>
    <div className={styles.grid}>
      {data.sections.map((section, i) => {
        let contactList = null
        if (section.contact_list) {
          contactList = (
            <ul className={styles.list}>
              {section.contact_list.map(({ link, text }, j) => {
                return (
                  <li key={j}>
                    <a href={link}>{text}</a>
                  </li>
                )
              })}
            </ul>
          )
        }
        let locationList = null
        if (section.location_list) {
          locationList = (
            <a href={section.google_maps_link} target='_blank' rel='noopener noreferrer'>
              <ul className={styles.list}>
                {section.location_list.map((item, j) => {
                  return (
                    <li key={j}>
                      {item}
                    </li>
                  )
                })}
              </ul>
            </a>
          )
        }
        return (
          <div className={styles.section} key={i}>
            <div className={styles.title}>{section.title}</div>
            {contactList}
            {locationList}
          </div>
        )
      })}
    </div>
  </div>
)

MegamenuContact.propTypes = {
  data: PropTypes.object.isRequired
}

export default MegamenuContact
