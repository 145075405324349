import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { VelocityComponent } from 'velocity-react'
import DropdownAnimation from '../DropdownAnimation/DropdownAnimation'
import Icon from '../Icon/Icon'
import BookHeader from '../BookHeader/BookHeader'
import BookForm from '../BookForm/BookForm'
import MobilePhoneLink from '../MobilePhoneLink/MobilePhoneLink'
import styles from './MobileBook.module.scss'

class MobileBook extends Component {
  constructor (props) {
    super(props)
    this.state = {
      formVisible: false
    }
  }

  toggleForm () {
    if (this.state.formVisible) {
      this.setState({
        formVisible: false
      })
    } else {
      this.setState({
        formVisible: true
      })
    }
  }

  render () {
    let closeIcon = null
    if (this.props.isMobileHeader) {
      closeIcon = (
        <div className={styles.closeArrowIcon}>
          <Icon name='smallArrowRightGold' />
        </div>
      )
    } else {
      closeIcon = (
        <div className={styles.closeIcon}>
          <Icon name='modalClose' />
        </div>
      )
    }
    let modifier = ''
    if (this.props.isMobileHeader) {
      modifier = styles.isMobileHeader
    } else if (this.props.centerLayout) {
      modifier = styles.centerLayout
    } else if (this.props.leftLayout) {
      modifier = styles.leftLayout
    }
    let phoneLink = null
    if (!this.props.isMobileHeader) {
      phoneLink = (
        <MobilePhoneLink link={this.props.phoneLink} />
      )
    }
    return (
      <div className={`${styles.wrap} ${modifier}`}>
        <div
          role='button'
          tabIndex={0}
          className={`${styles.button} ${this.state.formVisible ? styles.isActive : ''}`}
          onClick={() => { this.toggleForm() }}
        >
          <div className={styles.buttonGrid}>
            <div className={styles.text}>
              {this.props.labels.book}
            </div>
            <div className={styles.arrowIcon}>
              <Icon name='smallArrowRightBlack' />
            </div>
            {closeIcon}
          </div>
        </div>
        <DropdownAnimation
          animation={this.state.formVisible ? 'slideDown' : 'slideUp'}
          easing='easeInOutSine'
          duration={300}
          delay={this.state.formVisible ? 0 : 300}
        >
          <div className={styles.form}>
            <VelocityComponent
              animation={{
                opacity: this.state.formVisible ? 1 : 0
              }}
              duration={400}
              delay={this.state.formVisible ? 200 : 0}
            >
              <div className={styles.inner}>
                <div className={styles.innerContainer}>
                  <div className={styles.innerWrap}>
                    <BookHeader
                      label={this.props.header.label}
                      title={this.props.header.title}
                      noTopPadding
                    />
                    <BookForm
                      locale={this.props.locale}
                      labels={this.props.labels}
                      dropdown
                      pinned={false}
                    />
                  </div>
                  {phoneLink}
                </div>
              </div>
            </VelocityComponent>
          </div>
        </DropdownAnimation>
      </div>
    )
  }
}

MobileBook.propTypes = {
  locale: PropTypes.string.isRequired,
  labels: PropTypes.object.isRequired,
  header: PropTypes.object.isRequired,
  isMobileHeader: PropTypes.bool,
  centerLayout: PropTypes.bool,
  phoneLink: PropTypes.object,
  leftLayout: PropTypes.bool
}

export default MobileBook
