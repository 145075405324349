import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import Icon from '../Icon/Icon'
import styles from './LinkedPosts.module.scss'

const LinkedPosts = ({ edges, fullWidth, relatedPosts, noImages }) => {
  return (
    <div
      className={`${styles.container} ${fullWidth ? styles.fullWidth : ''} ${
        relatedPosts ? styles.relatedPosts : ''
      } ${noImages ? styles.noImages : ''}`}
    >
      <div className={styles.grid}>
        {edges.map(({ node }) => {
          const { id, path, title, acf, categories } = node
          const { featuredMedia, subtitle } = acf
          let sizes = null
          let duotoneSizes = null
          let altText = ''
          let localFile = null
          let sourceUrl = null

          if (featuredMedia) {
            altText = featuredMedia.altText
            localFile = featuredMedia.localFile
            sourceUrl = featuredMedia.sourceUrl
            sizes = localFile ? localFile.childImageSharp.croppedSizes : null
            duotoneSizes = localFile
              ? localFile.childImageSharp.croppedSizesDuotone
              : null
          }

          return (
            <div key={id} className={styles.col}>
              <div className={styles.item}>
                {!noImages && (
                  <div className={styles.image}>
                    {sizes && <Img fluid={sizes} alt={altText} />}
                    {!sizes && sourceUrl && (
                      <div className={styles.imageBackup}>
                        <img src={sourceUrl} alt={altText} />
                      </div>
                    )}
                    {duotoneSizes && (
                      <div className={styles.duotoneImage}>
                        <Img fluid={duotoneSizes} alt={altText} />
                      </div>
                    )}
                    {!sizes && !sourceUrl && (
                      <div className={styles.imagePlaceholder}>
                        <Icon name='mainHeaderBrand' />
                      </div>
                    )}
                  </div>
                )}
                {categories && (
                  <ul className={styles.categories}>
                    {categories.map(({ id, name }) => {
                      return <li key={id}>{name}</li>
                    })}
                  </ul>
                )}
                <div className={styles.title}>
                  <Link to={path}>
                    <span>{title}</span>
                  </Link>
                </div>
                {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

LinkedPosts.propTypes = {
  edges: PropTypes.array.isRequired,
  fullWidth: PropTypes.bool,
  noImages: PropTypes.bool
}

export default LinkedPosts
