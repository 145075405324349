import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import Modal from '../Modal/Modal'
import BookHeader from '../BookHeader/BookHeader'
import BookForm from '../BookForm/BookForm'
import styles from './FixedBook.module.scss'

class FixedBook extends Component {
  constructor (props) {
    super(props)
    this.state = {
      showModal: false
    }
  }

  render () {
    const { header, labels, locale } = this.props
    return (
      <Fragment>
        <button
          className={styles.button}
          onClick={() => this.setState({ showModal: true })}
        >
          <span>Book<br />now</span>
        </button>
        <Modal
          isOpen={this.state.showModal}
          onRequestClose={() => this.setState({ showModal: false })}
          small
        >
          <Fragment>
            <BookHeader
              label={header.label}
              title={header.title}
              noTopPadding
            />
            <BookForm locale={locale} labels={labels} pinned={false} />
          </Fragment>
        </Modal>
      </Fragment>
    )
  }
}

FixedBook.propTypes = {
  header: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired
}

export default FixedBook
