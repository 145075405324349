import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Icon from '../Icon/Icon'
import styles from './MobilePhoneLink.module.scss'

const MobilePhoneLink = ({ link, isMobileHeader }) => {
  const modifier = isMobileHeader ? styles.isMobileHeader : ''
  return (
    <div className={`${styles.wrap} ${modifier}`}>
      <div className={styles.grid}>
        <a href={link.url} className={styles.link}>{link.title}</a>
        <div className={styles.icon}>
          <Icon name='phone' />
        </div>
      </div>
    </div>
  )
}

MobilePhoneLink.propTypes = {
  link: PropTypes.object.isRequired,
  isMobileHeader: PropTypes.bool
}

export default MobilePhoneLink
