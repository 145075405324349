import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

class Image extends React.Component {
  parseImages (images) {
    Array.prototype.forEach.call(images, (image) => {
      if (image.hasAttribute('sizes')) {
        this.updateSizesAttr(image)
      }
    })
  }

  updateSizesAttr (image) {
    const parent = image.parentNode
    const width = window.getComputedStyle(parent).width
    image.setAttribute('sizes', width)
  }

  componentDidMount () {
    const parse = () => {
      const images = this.refs.imageContainer.querySelectorAll('img')
      if (images) {
        this.parseImages(images)
      }
    }
    setTimeout(() => {
      window.requestAnimationFrame(() => {
        if (this.refs.imageContainer) {
          parse()
        }
      })
    }, 300)
  }

  render () {
    return (
      <div ref='imageContainer'>
        <Img
          fluid={this.props.fluid}
          {...this.props.imageProps}
        />
      </div>
    )
  }
}

Image.propTypes = {
  fluid: PropTypes.object.isRequired,
  imageProps: PropTypes.object
}

export default Image
