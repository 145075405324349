import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { VelocityComponent } from 'velocity-react'
import ReactHoverObserver from 'react-hover-observer'
import { connect } from 'react-redux'
import Icon from '../Icon/Icon'
import { Link } from 'gatsby'
import MainNavDesktopButton from '../MainNavDesktopButton/MainNavDesktopButton'
import DesktopNav from '../DesktopNav/DesktopNav'
import Megamenu from '../Megamenu/Megamenu'
import styles from './DesktopHeader.module.scss'

const MAIN_NAV_MEGAMENU_CONFIG = [{ attr: 'main-menu' }]
const BOOK_MEGAMENU_CONFIG = [{ attr: 'megamenu-book' }]

class DesktopHeader extends Component {
  constructor (props) {
    super(props)
    this.state = {
      langNavVisible: false,
      megamenuVisible: false,
      megamenuActive: false,
      megamenuHovering: false,
      megamenu: {
        attr: null,
        type: null
      }
    }
  }

  handleMegamenu (data) {
    if (data.megamenu.attr === '') return

    const langNavVisible = this.state.langNavVisible
    const megamenuActive = this.state.megamenuActive
    const megamenuVisible = this.state.megamenuVisible
    const megamenuHovering = this.state.megamenuHovering
    const updateWithDelay = () => {
      this.setState({
        megamenuVisible: false
      })
      setTimeout(() => {
        this.setState({
          megamenu: data.megamenu
        })
      }, 100)
      setTimeout(() => {
        if (data.active) {
          this.setState({
            megamenuVisible: data.active
          })
        }
      }, 300)
    }
    const updateWhileActive = () => {
      this.setState({
        megamenu: data.megamenu,
        megamenuVisible: data.active,
        megamenuActive: true
      })
    }

    if (langNavVisible) {
      this.setState({
        langNavVisible: false
      })
      updateWithDelay()
    }

    if (megamenuActive && !megamenuHovering) {
      updateWithDelay()
    } else if (!megamenuHovering) {
      updateWhileActive()
    }

    if (!data.active && !megamenuHovering && megamenuActive) {
      setTimeout(() => {
        this.setState({
          megamenuActive: false
        })
      }, 300)
    }
  }

  megamenuOnHover (active) {
    this.setState({
      megamenuVisible: active,
      megamenuHovering: active
    })
  }

  toggleLangNav () {
    if (this.state.langNavVisible) {
      this.setState({
        langNavVisible: false
      })
    } else {
      this.setState({
        langNavVisible: true
      })
    }
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.locationKey !== this.props.locationKey) {
      setTimeout(() => {
        this.setState({
          ...this.state,
          megamenuActive: false,
          megamenuVisible: false
        })
      }, 800)
    }
  }

  render () {
    const leftData = this.props.navMenuLeft
    const leftNav = leftData.nav.items
    const rightData = this.props.navMenuRight
    const rightNav = rightData.nav.items
    const mainNavButtonLabel = leftData.main_nav_button.label
    const allNavs = leftNav.concat(
      rightNav,
      MAIN_NAV_MEGAMENU_CONFIG,
      BOOK_MEGAMENU_CONFIG
    )
    const showBorderModifier = this.props.showBorder ? styles.showBorder : ''
    const bookLabel = this.props.bookContent.room.dropdown.label
    const homeLink = this.props.locale === 'es' ? '/' : this.props.locale
    return (
      <div className={styles.outer}>
        <div className={styles.wrap}>
          <div className={`${styles.top} ${showBorderModifier}`}>
            <div className={`${styles.navs} ${styles.left}`}>
              <MainNavDesktopButton
                text={mainNavButtonLabel}
                handleMegamenu={data => {
                  this.handleMegamenu(data)
                }}
              />
              <DesktopNav
                items={leftNav}
                handleMegamenu={data => {
                  this.handleMegamenu(data)
                }}
                activeMegamenu={{
                  attr: this.state.megamenu.attr,
                  visible: this.state.megamenuVisible
                }}
              />
            </div>
            <div className={styles.brand}>
              <Link to={homeLink}>
                <Icon name='mainHeaderBrand' />
              </Link>
            </div>
            <div className={`${styles.navs} ${styles.right}`}>
              <DesktopNav
                items={rightNav}
                handleMegamenu={data => {
                  this.handleMegamenu(data)
                }}
                activeMegamenu={{
                  attr: this.state.megamenu.attr,
                  visible: this.state.megamenuVisible
                }}
                bookLabel={bookLabel}
                toggleLangNav={() => {
                  this.toggleLangNav()
                }}
                langNavVisible={this.state.langNavVisible}
                right
                translations={this.props.translations}
              />
            </div>
          </div>
          <VelocityComponent
            animation={{
              translateY: this.state.megamenuVisible
                ? ['0px', '-5px']
                : ['-5px', '0px'],
              translateZ: '-1px'
            }}
            display={this.state.megamenuVisible ? 'block' : 'none'}
            easing='easeInOutSine'
            duration={300}
          >
            <div className={styles.megamenu}>
              {allNavs.map((item, i) => {
                const attr = item.attr
                const isMegamenu = attr.indexOf('megamenu') !== -1
                const isMainNav = attr.indexOf('main-menu') !== -1
                if (isMegamenu || isMainNav) {
                  const visible =
                    this.state.megamenuVisible &&
                    attr === this.state.megamenu.attr
                  let type
                  if (attr === 'megamenu-rooms') {
                    type = 'rooms'
                  } else if (attr === 'megamenu-restaurants') {
                    type = 'restaurants'
                  } else if (attr === 'megamenu-book') {
                    type = 'book'
                  } else if (attr.includes('megamenu-contact')) {
                    type = 'contact'
                  } else if (attr === 'main-menu') {
                    type = 'mainNav'
                  }
                  return (
                    <VelocityComponent
                      key={i}
                      animation={{
                        opacity: visible ? [1, 0] : [0, 1]
                      }}
                      display={visible ? 'block' : 'none'}
                      duration={300}
                    >
                      <div className={styles.megamenuInner}>
                        <ReactHoverObserver hoverOffDelayInMs={100}>
                          <Megamenu
                            linkedRooms={this.props.megamenu.linked_rooms}
                            linkedRestaurants={
                              this.props.megamenu.linked_restaurants
                            }
                            contact={this.props.megamenu.contact}
                            bookContent={this.props.bookContent}
                            mainNav={this.props.megamenu.main_nav.items}
                            type={type}
                            isActive={active => {
                              this.megamenuOnHover(active)
                            }}
                            locale={this.props.locale}
                            locationKey={this.props.locationKey}
                          />
                        </ReactHoverObserver>
                      </div>
                    </VelocityComponent>
                  )
                }
              })}
            </div>
          </VelocityComponent>
        </div>
      </div>
    )
  }
}

DesktopHeader.propTypes = {
  locale: PropTypes.string.isRequired,
  navMenuLeft: PropTypes.object.isRequired,
  navMenuRight: PropTypes.object.isRequired,
  megamenu: PropTypes.object.isRequired,
  bookContent: PropTypes.object.isRequired,
  locationKey: PropTypes.string.isRequired,
  translations: PropTypes.array
}

const mapStateToProps = ({ showBorder }) => {
  return { showBorder }
}

const ConnectedDesktopHeader = connect(mapStateToProps)(DesktopHeader)

export default ConnectedDesktopHeader
