import React, { Component } from 'react'
import Img from 'gatsby-image'
import styles from './Gallery.module.scss'

const trans = {
  viewMore: {
    es: 'Ver más',
    ca: "Veure'n més",
    en: 'View more',
    fr: 'Voir plus'
  }
}

class Gallery extends Component {
  constructor() {
    super()
    this.state = {
      count: 12
    }
  }

  showMore() {
    this.setState({
      count: this.state.count += 12
    })
  }

  render() {
    return (
      <div className={`${styles.gallery} ${this.props.index % 2 ? styles.bgBlackDark : null}`}>
        <div className={styles.galleryTitle}>{this.props.title}</div>
        <div className={styles.galleryItems}>
          {this.props.items.map((item, index) => {
            return (
              <div
                key={item.id}
                onClick={() => this.props.openPswpById(item.id)}
                role="button"
                style={{ display: (index + 1) > this.state.count ? 'none': 'block'}}
              >
                <Img fluid={item.localFile.childImageSharp.fluid} />
              </div>
            )
          })}
        </div>
        {this.props.items.length > this.state.count && (<div className={styles.readMoreBtn}>
          <button onClick={() => this.showMore()}>{trans.viewMore[this.props.locale]}</button>
        </div>)}
      </div>
    )
  }
}

export default Gallery
