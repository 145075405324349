import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import Modal from '../Modal/Modal'
import PromoContent from '../PromoContent/PromoContent'
import styles from './PromoGrid.module.scss'

const PromoGrid = ({ items, bookLabel }) => {
  return (
    <div className={styles.wrap} id='promos'>
      <div className={styles.container}>
        <div className={styles.grid}>
          {items.map(
            (
              {
                title,
                subtitle,
                dates,
                intro,
                gallery,
                sliderGallery,
                flyerImage,
                description,
                features,
                addendum
              },
              i
            ) => {
              const props = {
                title,
                subtitle,
                dates,
                intro,
                gallery,
                sliderGallery,
                flyerImage,
                description,
                features,
                addendum
              }
              return (
                <div key={i} className={styles.column}>
                  <Item {...props} bookLabel={bookLabel} />
                </div>
              )
            }
          )}
        </div>
      </div>
    </div>
  )
}

PromoGrid.propTypes = {
  items: PropTypes.array.isRequired,
  bookLabel: PropTypes.string.isRequired
}

class Item extends Component {
  constructor (props) {
    super(props)
    this.state = {
      modalVisible: false
    }
  }

  render () {
    const { title, subtitle, gallery, bookLabel } = this.props
    const backupSourceUrl = gallery[0].source_url
    const imgAltText = gallery[0].alt_text
    const localFile = gallery[0].localFile
    const sizes = localFile ? localFile.childImageSharp.croppedSizes : null
    const duotoneSizes = localFile
      ? localFile.childImageSharp.croppedSizesDuotone
      : null
    const subtitleEl = subtitle ? (
      <h3 className={styles.subtitle}>{subtitle}</h3>
    ) : null
    return (
      <div className={styles.item}>
        <div
          className={styles.inner}
          onClick={() => {
            this.setState({ modalVisible: true })
          }}
        >
          <div className={styles.image}>
            {sizes && <Img fluid={sizes} />}
            {!sizes && (
              <div className={styles.imageBackup}>
                <img src={backupSourceUrl} alt={imgAltText} />
              </div>
            )}
            <div className={styles.duotoneImage}>
              {duotoneSizes && <Img fluid={duotoneSizes} />}
            </div>
          </div>
          <div className={styles.title}>
            <span>{title}</span>
          </div>
          {subtitleEl}
          <div className={styles.button}>
            <span className={styles.text}>{bookLabel}</span>
          </div>
        </div>
        <Modal
          isOpen={this.state.modalVisible}
          onRequestClose={() => {
            this.setState({ modalVisible: false })
          }}
        >
          <PromoContent {...this.props} />
        </Modal>
      </div>
    )
  }
}

Item.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  intro: PropTypes.string,
  gallery: PropTypes.array.isRequired,
  sliderGallery: PropTypes.array.isRequired,
  flyerImage: PropTypes.object,
  description: PropTypes.string,
  features: PropTypes.object,
  addendum: PropTypes.string,
  bookLabel: PropTypes.string
}

export default PromoGrid
