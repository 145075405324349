import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import ImageSlider from '../ImageSlider/ImageSlider'
import SmallWysiwyg from '../SmallWysiwyg/SmallWysiwyg'
import styles from './PromoContent.module.scss'

const BOOK_APP_URL =
  'https://app.thebookingbutton.com/properties/grauroigdirect'

const PromoContent = props => {
  const parentTitle = props.parentTitle
    ? <div className={styles.parentTitle}>{props.parentTitle}</div>
    : null
  const dates = props.dates
    ? <div className={styles.dates}>{props.dates}</div>
    : null
  const intro = props.intro
    ? <div
      className={styles.intro}
      dangerouslySetInnerHTML={{ __html: props.intro }}
      />
    : null
  const description = props.description
    ? <SmallWysiwyg content={props.description} />
    : null
  const features = props.features.list[0].list_item !== ''
    ? <Features title={props.features.title} list={props.features.list} />
    : null
  const addendum = props.addendum
    ? <p
      className={styles.addendum}
      dangerouslySetInnerHTML={{ __html: props.addendum }}
      />
    : null
  let flyerImage = null
  if (props.flyerImage) {
    const backupSourceUrl = props.flyerImage.source_url
    const imgAltText = props.flyerImage.alt_text
    const localFile = props.flyerImage.localFile
    const fluid = localFile ? localFile.childImageSharp.fluid : null
    flyerImage = (
      <div className={styles.flyerImage}>
        {fluid && <Img fluid={fluid} alt={imgAltText} />}
        {!fluid &&
          <div className={styles.flyerImageBackup}>
            <img src={backupSourceUrl} alt={imgAltText} />
          </div>}
      </div>
    )
  }
  return (
    <div className={styles.wrap}>
      {parentTitle}
      <h2 className={styles.title}>{props.title}</h2>
      {dates}
      <a href={BOOK_APP_URL} target='_blank' className={styles.bookButton}>
        <span className={styles.text}>{props.bookLabel}</span>
      </a>
      <div className={styles.gallery}>
        <ImageSlider images={props.sliderGallery} />
      </div>
      {intro}
      {flyerImage}
      {description}
      {features}
      <a href={BOOK_APP_URL} target='_blank' className={styles.bookButton}>
        <span className={styles.text}>{props.bookLabel}</span>
      </a>
      {addendum}
    </div>
  )
}

PromoContent.propTypes = {
  parentTitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  dates: PropTypes.string,
  intro: PropTypes.string,
  sliderGallery: PropTypes.array.isRequired,
  flyerImage: PropTypes.object,
  description: PropTypes.string,
  features: PropTypes.object,
  addendum: PropTypes.string,
  bookLabel: PropTypes.string
}

const Features = ({ title, list }) => {
  const titleEl = title
    ? <h3 className={styles.featuresTitle}>{title}</h3>
    : null
  return (
    <div className={styles.features}>
      {titleEl}
      <ul className={styles.featuresList}>
        {list.map(({ list_item }, i) => {
          return <li key={i}>{list_item}</li>
        })}
      </ul>
    </div>
  )
}

Features.propTypes = {
  title: PropTypes.string,
  list: PropTypes.array.isRequired
}

export default PromoContent

/*
<button type='button' className={styles.bookButton}>
  <span className={styles.text}>{props.bookLabel}</span>
</button>
*/
