import React from 'react'
import PropTypes from 'prop-types'
import LinkedRestaurants from '../LinkedRestaurants/LinkedRestaurants'
import styles from './HomeLinkedRestaurants.module.scss'

const HomeLinkedRestaurants = props => (
  <div>
    <div className={styles.info}>
      <h2 className={styles.title}>
        {props.title}
      </h2>
      <div
        className={styles.content}
        dangerouslySetInnerHTML={{ __html: props.content}}
      />
      <div className={styles.overlay} />
    </div>
    <div className={styles.linked}>
      <LinkedRestaurants
        items={props.items}
        noTitle
      />
    </div>
  </div>
)

HomeLinkedRestaurants.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  items: PropTypes.array.isRequired
}

export default HomeLinkedRestaurants
