import React from 'react'
import PropTypes from 'prop-types'
import BookHeader from '../BookHeader/BookHeader'
import BookContact from '../BookContact/BookContact'
import { MediaQueryProvider, MediaQuery } from 'react-responsive-hoc'
import Image from '../Image/Image'
import styles from './StandaloneBookContact.module.scss'

const StandaloneBookContact = props => {
  const label = props.data.label
  const title = props.data.title
  const contact = props.data.contact
  const addendum = props.data.addendum
  const localFile = props.data.image.localFile
  const sizes = localFile ? localFile.childImageSharp.fluid : null

  return (
    <div className={styles.wrap}>
      <div className={styles.info}>
        <BookHeader label={label} title={title} />
        <BookContact list={contact} addendum={addendum} />
      </div>
      <MediaQueryProvider width={1440} height={900}>
        <MediaQuery query='(min-width: 427px)'>
          {localFile &&
            <div className={styles.image}>
              <Image
                fluid={sizes}
                imageProps={{
                  style: {
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0
                  }
                }}
              />
            </div>
          }
        </MediaQuery>
      </MediaQueryProvider>
    </div>
  )
}

StandaloneBookContact.propTypes = {
  data: PropTypes.object.isRequired
}

export default StandaloneBookContact
