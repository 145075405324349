import React from 'react'
import styles from './CenterOverlay.module.scss'

const CenterOverlay = props => {
  const paddingModifier = props.noTopPadding ? styles.noTopPadding : ''
  return (
    <div className={`${styles.wrap} ${paddingModifier}`}>
      <div className={`${styles.children} fade-in-animation`}>
        {props.children}
      </div>
      <div className={`${styles.overlay} fade-in-animation`} />
    </div>
  )
}

export default CenterOverlay
