import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import { connect } from 'react-redux'

// https://github.com/jordan-enev/react-velocity-scroll
const Velocity = require('../../../node_modules/velocity-react/lib/velocity-animate-shim')

const scrollAnimation = (id, cb) => {
  const el = document.querySelector(id)
  Velocity(el, 'scroll', {
    duration: 2000,
    easing: 'easeOutQuart',
    mobileHA: false,
    offset: -80,
    complete: () => {
      if (typeof cb !== 'undefined') {
        cb()
      }
    }
  })
}

const DefaultAnchorScrollButton = ({ attr, url, title, setScrollTarget }) => (
  <button
    type='button'
    onClick={() => {
      setScrollTarget(attr)
      navigate(url)
    }}
  >
    {title}
  </button>
)

DefaultAnchorScrollButton.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  attr: PropTypes.string.isRequired
}

const mapStateToProps = ({ setScrollTarget }) => {
  return { setScrollTarget }
}

const mapDispatchToProps = dispatch => {
  return { setScrollTarget: (scrollTarget) => dispatch({
    type: `SCROLL_TO`,
    scrollTarget
  }) }
}

const AnchorScrollButton = connect(mapStateToProps, mapDispatchToProps)(DefaultAnchorScrollButton)

export default AnchorScrollButton
