import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import BookHeader from '../BookHeader/BookHeader'
import BookForm from '../BookForm/BookForm'
import BookContact from '../BookContact/BookContact'
import styles from './MegamenuBook.module.scss'

const Image = (props) => {
  return (
    <div className={styles.image}>
      <Img
        fluid={props.fluid}
        alt={props.alt}
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0
        }}
      />
    </div>
  )
}

class MegamenuBook extends Component {
  render () {
    let blocks = []
    let restaurantObj = {}
    let roomObj = {}
    let spaObj = {}
    restaurantObj.restaurant = this.props.bookContent.restaurant
    roomObj.room = this.props.bookContent.room
    spaObj.spa = this.props.bookContent.spa
    blocks.push(restaurantObj)
    blocks.push(roomObj)
    blocks.push(spaObj)
    return (
      <div className={styles.wrap}>
        <div className={styles.grid}>
          {blocks.map((block, i) => {
            if (block.restaurant) {
              const data = block.restaurant
              const backupSourceUrl = data.dropdown.image.source_url
              const imgAltText = data.dropdown.image.alt_text
              const localFile = data.dropdown.image.localFile
              const sizes = localFile ? localFile.childImageSharp.fluid : null
              const label = data.dropdown.label
              const title = data.dropdown.title
              const list = data.dropdown.content.book_contact.list
              const addendum = data.dropdown.content.book_contact.addendumm
              return (
                <div className={styles.column} key={i}>
                  <div className={styles.info}>
                    <BookHeader
                      label={label}
                      title={title}
                    />
                    <BookContact
                      list={list}
                      addendum={addendum}
                    />
                  </div>
                  {sizes &&
                    <Image fluid={sizes} alt={imgAltText} />
                  }
                  {!sizes &&
                    <div className={styles.imageBackup}>
                      <img src={backupSourceUrl} alt={imgAltText} />
                    </div>
                  }
                </div>
              )
            } else if (block.room) {
              const data = block.room
              const backupSourceUrl = data.dropdown.image.source_url
              const imgAltText = data.dropdown.image.alt_text
              const localFile = data.dropdown.image.localFile
              const sizes = localFile ? localFile.childImageSharp.fluid : null
              const label = data.dropdown.label
              const title = data.dropdown.title
              const formLabels = data.dropdown.content.book_form.book_form_labels
              return (
                <div className={styles.column} key={i}>
                  <div className={styles.info}>
                    <BookHeader label={label} title={title} />
                    <BookForm locale={this.props.locale} labels={formLabels} pinned />
                  </div>
                  {sizes &&
                    <Image fluid={sizes} alt={imgAltText} />
                  }
                  {!sizes &&
                    <div className={styles.imageBackup}>
                      <img src={backupSourceUrl} alt={imgAltText} />
                    </div>
                  }
                </div>
              )
            } else if (block.spa) {
              const data = block.spa
              const backupSourceUrl = data.dropdown.image.source_url
              const imgAltText = data.dropdown.image.alt_text
              const localFile = data.dropdown.image.localFile
              const sizes = localFile ? localFile.childImageSharp.fluid : null
              const label = data.dropdown.label
              const title = data.dropdown.title
              const list = data.dropdown.content.book_contact.list
              const addendum = data.dropdown.content.book_contact.addendumm
              return (
                <div className={styles.column} key={i}>
                  <div className={styles.info}>
                    <BookHeader
                      label={label}
                      title={title}
                      />
                    <BookContact
                      list={list}
                      addendum={addendum}
                      />
                  </div>
                  {sizes &&
                    <Image fluid={sizes} alt={imgAltText} />
                  }
                  {!sizes &&
                    <div className={styles.imageBackup}>
                      <img src={backupSourceUrl} alt={imgAltText} />
                    </div>
                  }
                </div>
              )
            }
          })}
        </div>
      </div>
    )
  }
}

MegamenuBook.propTypes = {
  bookContent: PropTypes.object.isRequired
}

export default MegamenuBook

