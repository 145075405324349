import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactHoverObserver from 'react-hover-observer'
import { Link } from 'gatsby'
import Icon from '../Icon/Icon'
import DesktopLangNav from '../DesktopLangNav/DesktopLangNav'
import styles from './DesktopNav.module.scss'

const DesktopNav = props => {
  const bookMegamenu = {
    attr: 'megamenu-book'
  }
  let langNav = null
  let book = null
  if (props.right) {
    langNav = (
      <li>
        <DesktopLangNav
          toggle={() => {
            props.toggleLangNav()
          }}
          visible={props.langNavVisible}
          translations={props.translations}
        />
      </li>
    )
    book = (
      <li className={styles.bookHighlight}>
        <ReactHoverObserver hoverDelayInMs={150} hoverOffDelayInMs={150}>
          <DesktopNavLink
            text={props.bookLabel}
            megamenu={bookMegamenu}
            bookHighlight
            handleMegamenu={props.handleMegamenu}
            activeMegamenu={props.activeMegamenu}
          />
        </ReactHoverObserver>
      </li>
    )
  }

  return (
    <ul className={styles.desktopNav}>
      {props.items.map((item, i) => {
        const megamenu = {
          attr: item.attr
        }
        return (
          <li key={i}>
            <ReactHoverObserver hoverDelayInMs={150} hoverOffDelayInMs={150}>
              <DesktopNavLink
                text={item.title}
                link={item.object_slug}
                url={item.url}
                megamenu={megamenu}
                handleMegamenu={props.handleMegamenu}
                activeMegamenu={props.activeMegamenu}
                externalLink={item.attr.includes('external-link')}
              />
            </ReactHoverObserver>
          </li>
        )
      })}
      {langNav}
      {book}
    </ul>
  )
}

class DesktopNavLink extends Component {
  componentWillUpdate (nextProps) {
    const diffProps = this.props.isHovering !== nextProps.isHovering
    if (diffProps && this.props.megamenu && !this.props.externalLink) {
      const data = {
        active: nextProps.isHovering,
        megamenu: nextProps.megamenu
      }
      this.props.handleMegamenu(data)
    }
  }

  render () {
    const attr = this.props.megamenu.attr
    const activeMegamenuAttr = this.props.activeMegamenu.attr
    const megamenuVisible = this.props.activeMegamenu.visible
    const modifier =
      activeMegamenuAttr === attr && megamenuVisible
        ? styles.activeMegamenu
        : ''
    let childContent = null
    if (attr.includes('location-icon')) {
      childContent = (
        <div className={styles.withIcon}>
          <Icon name='desktopHeaderLocation' />
          <span>{this.props.text}</span>
        </div>
      )
    } else if (attr.includes('icon-whatsapp')) {
      childContent = (
        <div className={styles.withIcon}>
          <Icon name="whatsapp" role="presentation" />
          <span>{this.props.text}</span>
        </div>
      )
    } else {
      childContent = <span>{this.props.text}</span>
    }
    let child = null
    if (this.props.externalLink) {
      child = <a href={this.props.url}>{childContent}</a>
    } else if (this.props.bookHighlight) {
      child = <button type='button'>{childContent}</button>
    } else {
      const url = this.props.url.replace(/https?:\/\/[^\/]+/i, '')
      child = (
        <Link to={url} className={modifier} activeClassName={styles.active}>
          {childContent}
        </Link>
      )
    }
    return child
  }
}

DesktopNav.propTypes = {
  items: PropTypes.array.isRequired,
  handleMegamenu: PropTypes.func,
  activeMegamenu: PropTypes.object,
  bookLabel: PropTypes.string,
  right: PropTypes.bool,
  translations: PropTypes.array
}

export default DesktopNav
