import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { VelocityComponent } from 'velocity-react'
import { connect } from 'react-redux'
import Alert from '../Alert/Alert'
import Image from '../Image/Image'
import styles from './Hero.module.scss'

class DefaultHero extends Component {
  setWrapHeight () {
    const height = this.wrap.clientHeight
    this.wrap.setAttribute('style', `height: ${height}px;`)
  }

  componentDidMount () {
    if (typeof window !== 'undefined') {
      const cb = () => {
        if (!this.wrap) return

        if (this.wrap.getBoundingClientRect().bottom <= 0) {
          this.props.showHeroBorder(true)
        } else if (this.wrap.getBoundingClientRect().top <= 0) {
          this.props.showHeroBorder(false)
        }
      }
      window.addEventListener('scroll', cb)
    }
  }

  render () {
    const modifier = this.props.beta ? styles.beta : ''
    let parentLink = null
    if (this.props.parentPage) {
      parentLink = (
        <VelocityComponent
          animation={{
            opacity: 1,
            translateY: ['0rem', '1rem']
          }}
          duration={1100}
          easing='easeOutQuart'
          delay={600}
          runOnMount
          >
          <div className={`${styles.parent} fade-in-animation`}>
            <Link to={this.props.parentPage.path}>
              {this.props.parentPage.title}
            </Link>
          </div>
        </VelocityComponent>
      )
    }

    let titleEl = null
    if (this.props.title) {
      titleEl = (
        <VelocityComponent
          animation={{
            opacity: 1,
            translateY: ['0rem', '2rem']
          }}
          duration={1000}
          easing='easeOutQuart'
          delay={100}
          runOnMount
        >
          <div className={`${styles.title} fade-in-animation`}>
            {this.props.title}
          </div>
        </VelocityComponent>
      )
    }
    return (
      <div className={`${styles.wrap} ${modifier}`} ref={(el) => { this.wrap = el }}>
        <div className={styles.info}>
          {parentLink}
          {titleEl}
        </div>
        <div className={styles.children}>
          {this.props.children}
        </div>
        <VelocityComponent
          animation={{
            opacity: 0.9
          }}
          duration={700}
          easing='easeInOutSine'
          runOnMount
        >
          <div className={`${styles.image} fade-in-animation`}>
            {this.props.imageSizes &&
              <Image
                fluid={this.props.imageSizes}
                imageProps={{
                  style: {
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0
                  },
                  alt: this.props.altText,
                  onLoad: () => {
                    this.setWrapHeight()
                  }
                }}
              />
            }
            {!this.props.imageSizes &&
              <img
                className={styles.backupImage}
                src={this.props.backupSourceUrl}
                alt={this.props.altText}
                onLoad={() => { this.setWrapHeight() }}
              />
            }
          </div>
        </VelocityComponent>
        { this.props.alert &&
          <Alert message={this.props.alert} hasTitle={!! this.props.title } />
        }
      </div>
    )
  }
}

DefaultHero.propTypes = {
  backupSourceUrl: PropTypes.string.isRequired,
  imageSizes: PropTypes.object,
  altText: PropTypes.string,
  title: PropTypes.string,
  parentPage: PropTypes.object,
  alert: PropTypes.string,
}

const mapStateToProps = ({ showHeroBorder }) => {
  return { showHeroBorder }
}

const mapDispatchToProps = dispatch => {
  return { showHeroBorder: (show) => dispatch({
    type: `SHOW_DESKTOP_HEADER_BORDER`,
    showBorder: show
  }) }
}

const Hero = connect(mapStateToProps, mapDispatchToProps)(DefaultHero)

export default Hero
