const data = [
  {
    locale: 'es',
    languageCode: 'es-ES'
  },
  {
    locale: 'en',
    languageCode: 'en-GB'
  },
  {
    locale: 'ca',
    languageCode: 'ca-ES'
  },
  {
    locale: 'fr',
    languageCode: 'fr-FR'
  }
]

/**
 * @param {locale} string
 */
function getLanguageCode (locale) {
  const obj = data.find(item => item.locale === locale)
  return obj.languageCode
}

export default getLanguageCode
