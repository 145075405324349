import React, { Component } from 'react'
import PropTypes from 'prop-types'
import SectionTitle from '../SectionTitle/SectionTitle'
import Icon from '../Icon/Icon'
import Modal from '../Modal/Modal'
import ActivityContent from '../ActivityContent/ActivityContent'
import styles from './Activities.module.scss'

class ActivityItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false
    }
    this.toggleModal = this.toggleModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  toggleModal() {
    if (this.state.showModal) {
      this.setState({
        showModal: false
      })
    } else {
      this.setState({
        showModal: true
      })
    }
  }

  closeModal() {
    this.setState({
      showModal: false
    })
  }

  render() {
    return (
      <div className={styles.item}>
        <button
          type='button'
          className={styles.button}
          onClick={this.toggleModal}
        >
          <div className={styles.parent}>{this.props.parentTitle}</div>
          <div className={styles.titleGrid}>
            <div className={styles.title}>
              <span>{this.props.title}</span>
            </div>
            <div className={styles.icon}>
              <Icon name='smallArrowRightGold' />
            </div>
          </div>
        </button>
        <Modal isOpen={this.state.showModal} onRequestClose={this.closeModal}>
          <ActivityContent
            parentTitle={this.props.parentTitle}
            title={this.props.title}
            gallery={this.props.gallery}
            description={this.props.description}
            book={this.props.book}
            contact={this.props.contact}
          />
        </Modal>
      </div>
    )
  }
}

const Activities = props => (
  <div className={styles.wrap}>
    <SectionTitle text={props.shortTitle} htmlElement='h2' />
    <div className={styles.grid}>
      {props.items.map((item, i) => {
        const hasContact = item.book.contact_list[0].text !== ''
        return (
          <ActivityItem
            key={i}
            parentTitle={props.shortTitle}
            title={item.title}
            gallery={item.gallery}
            description={item.description}
            book={item.book}
            contact={hasContact ? item.book : props.contact}
          />
        )
      })}
    </div>
  </div>
)

Activities.propTypes = {
  items: PropTypes.array.isRequired,
  shortTitle: PropTypes.string,
  longTitle: PropTypes.string,
  contact: PropTypes.object.isRequired
}

export default Activities
