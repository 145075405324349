import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './BookFormSelect.module.scss'

const BookFormSelectItem = props => {
  const modifier = props.isActive ? styles.isActive : ''
  return (
    <li>
      <button
        type='button'
        onClick={() => { props.setValue(props.value) }}
        className={`${styles.button} ${modifier}`}
      >
        {props.text}
      </button>
    </li>
  )
}

const BookFormSelect = props => (
  <div className={styles.wrap}>
    <ul className={styles.list}>
      {props.items.map((item, i) => {
        return (
          <BookFormSelectItem
            key={i}
            value={item.value}
            text={item.text}
            isActive={item.value === props.currentValue}
            setValue={(value) => { props.handleValueChange(value) }}
          />
        )
      })}
    </ul>
  </div>
)

BookFormSelect.propTypes = {
  items: PropTypes.array.isRequired,
  currentValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  handleValueChange: PropTypes.func
}

export default BookFormSelect
