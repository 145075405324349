import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Icon from '../Icon/Icon'
import styles from './MainNavButton.module.scss'

class MainNavButton extends Component {
  render () {
    const modifier = this.props.invert ? styles.invert : ''
    return (
      <button
        onClick={() => {
          if (this.props.clickHandler) {
            this.props.clickHandler()
          }
        }}
        className={`${styles.button} ${modifier}`}
      >
        <span className={styles.icon}>
          <Icon name='mainNavButton' />
        </span>
        <span className={styles.text}>
          {this.props.text}
        </span>
      </button>
    )
  }
}

MainNavButton.propTypes = {
  text: PropTypes.string.isRequired
}

export default MainNavButton
