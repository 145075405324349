import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { VelocityComponent } from 'velocity-react'
import { MediaQueryProvider, MediaQuery } from 'react-responsive-hoc'
import Icon from '../Icon/Icon'
import MainNavButton from '../MainNavButton/MainNavButton'
import DropdownAnimation from '../DropdownAnimation/DropdownAnimation'
import MobileBook from '../MobileBook/MobileBook'
import BookHeader from '../BookHeader/BookHeader'
import BookForm from '../BookForm/BookForm'
import MobilePhoneLink from '../MobilePhoneLink/MobilePhoneLink'
import MobileMainNav from '../MobileMainNav/MobileMainNav'
import MobileBetaNav from '../MobileBetaNav/MobileBetaNav'
import MobileLangNav from '../MobileLangNav/MobileLangNav'
import styles from './MobileHeader.module.scss'

class MobileHeader extends Component {
  constructor (props) {
    super(props)
    this.state = {
      navsVisible: false,
      langNavVisible: false,
      visibleMainNavGroup: null
    }
  }

  showNavs () {
    this.setState({
      navsVisible: true,
      langNavVisible: false,
      visibleMainNavGroup: null
    })
  }

  hideNavs () {
    this.setState({
      navsVisible: false,
      langNavVisible: false,
      visibleMainNavGroup: null
    })
  }

  render () {
    const homeLink = this.props.locale === 'es' ? '/' : this.props.locale
    return (
      <div className={`${styles.outer} mobileHeader`}>
        <div className={styles.wrap}>
          <div className={styles.grid}>
            <div className={styles.brand}>
              <Link to={homeLink}>
                <Icon name='mainHeaderBrand' />
              </Link>
            </div>
            <div className={styles.buttons}>
              {this.props.whatsappLink && (
              <VelocityComponent
                animation={{
                  opacity: this.state.navsVisible ? 0 : 1
                }}
                duration={300}
                delay={this.state.navsVisible ? 0 : 300}
                display={this.state.navsVisible ? 'none' : 'block'}
              >
                <div className={styles.buttonContainer}>
                  <div className={styles.locationButton}>
                    <a href={this.props.whatsappLink.url} title="WhatsApp" >
                      <Icon name='whatsapp' role="presentation" />
                    </a>
                  </div>
                </div>
              </VelocityComponent>)}
              <VelocityComponent
                animation={{
                  opacity: this.state.navsVisible ? 0 : 1
                }}
                duration={300}
                delay={this.state.navsVisible ? 0 : 300}
                display={this.state.navsVisible ? 'none' : 'block'}
              >
                <div className={styles.buttonContainer}>
                  <div className={styles.locationButton}>
                    <Link to={this.props.locationPageUrl}>
                      <Icon name='mobileHeaderLocation' />
                    </Link>
                  </div>
                </div>
              </VelocityComponent>
              <VelocityComponent
                animation={{
                  opacity: this.state.navsVisible ? 0 : 1
                }}
                duration={300}
                delay={this.state.navsVisible ? 0 : 300}
                display={this.state.navsVisible ? 'none' : 'block'}
              >
                <div className={styles.buttonContainer}>
                  <MainNavButton
                    clickHandler={() => {
                      this.showNavs()
                    }}
                    text={this.props.mainNavButtonLabel}
                    invert
                  />
                </div>
              </VelocityComponent>
              <VelocityComponent
                animation={{
                  opacity: this.state.navsVisible ? 1 : 0
                }}
                duration={300}
                delay={this.state.navsVisible ? 300 : 0}
                display={this.state.navsVisible ? 'block' : 'none'}
              >
                <div className={styles.buttonContainer}>
                  <button
                    type='button'
                    className={styles.closeNavs}
                    onClick={() => {
                      this.hideNavs()
                    }}
                  >
                    <Icon name='modalClose' />
                  </button>
                </div>
              </VelocityComponent>
            </div>
          </div>
        </div>
        <DropdownAnimation
          animation={this.state.navsVisible ? 'slideDown' : 'slideUp'}
          easing='easeInOutSine'
          duration={300}
          delay={this.state.navsVisible ? 0 : 300}
        >
          <div className={styles.navs}>
            <VelocityComponent
              animation={{
                opacity: this.state.navsVisible ? 1 : 0
              }}
              duration={300}
              delay={this.state.navsVisible ? 300 : 0}
            >
              <div className={styles.inner}>
                <div className={styles.innerWrap}>
                  <div className={styles.innerGrid}>
                    <div className={styles.innerColumn}>
                      <div className={styles.bookContainer}>
                        <div>
                          <MediaQueryProvider width={1440} height={900}>
                            <MediaQuery query='(max-width: 682px)'>
                              <MobileBook
                                locale={this.props.locale}
                                labels={this.props.bookFormLabels}
                                header={this.props.bookFormHeader}
                                isMobileHeader
                              />
                            </MediaQuery>
                          </MediaQueryProvider>
                        </div>
                        <div>
                          <MediaQueryProvider width={1440} height={900}>
                            <MediaQuery query='(min-width: 683px)'>
                              <div className={styles.bookWrap}>
                                <BookHeader
                                  label={this.props.bookFormHeader.label}
                                  title={this.props.bookFormHeader.title}
                                  noTopPadding
                                />
                                <BookForm
                                  locale={this.props.locale}
                                  labels={this.props.bookFormLabels}
                                  pinned={false}
                                />
                              </div>
                            </MediaQuery>
                          </MediaQueryProvider>
                        </div>
                        <MobilePhoneLink
                          link={this.props.phoneLink}
                          isMobileHeader
                        />
                      </div>
                    </div>
                    <div className={styles.innerColumn}>
                      <MobileMainNav
                        items={this.props.mainNav}
                        hideNavs={() => {
                          this.hideNavs()
                        }}
                        visibleGroup={this.state.visibleMainNavGroup}
                        showGroup={visibleMainNavGroup => {
                          this.setState({
                            ...this.state,
                            visibleMainNavGroup
                          })
                        }}
                        locationKey={this.props.locationKey}
                      />
                      <MobileBetaNav items={this.props.betaNav} />
                      <MobileLangNav
                        hideNavs={() => {
                          this.hideNavs()
                        }}
                        isVisible={this.state.langNavVisible}
                        showLangNav={() => {
                          this.setState({
                            ...this.state,
                            langNavVisible: true
                          })
                        }}
                        locale={this.props.locale}
                        translations={this.props.translations}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </VelocityComponent>
          </div>
        </DropdownAnimation>
      </div>
    )
  }
}

MobileHeader.propTypes = {
  locale: PropTypes.string.isRequired,
  bookFormLabels: PropTypes.object.isRequired,
  bookFormHeader: PropTypes.object.isRequired,
  mainNav: PropTypes.array.isRequired,
  mainNavButtonLabel: PropTypes.string.isRequired,
  betaNav: PropTypes.array.isRequired,
  phoneLink: PropTypes.object.isRequired,
  locationKey: PropTypes.string.isRequired,
  locationPageUrl: PropTypes.string.isRequired,
  translations: PropTypes.array
}

export default MobileHeader
