import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { VelocityComponent } from 'velocity-react'

class DropdownAnimation extends Component {
  constructor (props) {
    super(props)
    this.begin = this.begin.bind(this)
  }

  begin (elements) {
    if (this.props.animation === 'slideDown') {
      elements.forEach((el) => {
        if (el.style.display === 'none') {
          el.style.height = null
        }
      })

      if (this.props.beginCb) {
        this.props.beginCb()
      }
    }
  }

  render () {
    const props = Object.assign({}, {begin: this.begin}, this.props)
    return (
      <VelocityComponent {...props}>
        {this.props.children}
      </VelocityComponent>
    )
  }
}

DropdownAnimation.propTypes = {

}

export default DropdownAnimation
