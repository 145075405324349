import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import Wysiwyg from '../Wysiwyg/Wysiwyg'
import styles from './TextImageGrid.module.scss'

const TextImageGrid = props => {
  const backupSourceUrl = props.data.image.source_url
  const imgAltText = props.data.image.alt_text
  const localFile = props.data.image.localFile
  const sizes = localFile ? localFile.childImageSharp.fluid : null
  const layout = props.data.layout
  let imageModifier
  let infoModifier
  if (layout === 'image_left') {
    imageModifier = styles.first
    infoModifier = styles.second
  } else if (layout === 'image_right') {
    imageModifier = styles.second
    infoModifier = styles.first
  }
  const wrapModifier = props.data.background ? styles.background : ''

  return (
    <div className={`${styles.wrap} ${wrapModifier}`}>
      <div className={styles.container}>
        <div className={styles.grid}>
          <div className={`${styles.imageColumn} ${imageModifier}`}>
            <div className={styles.columnInner}>
              <div className={styles.image}>
                {sizes &&
                  <Img fluid={sizes} alt={imgAltText} />
                }
                {!sizes &&
                  <div className={styles.imageBackup}>
                    <img src={backupSourceUrl} alt={imgAltText} />
                  </div>
                }
              </div>
            </div>
          </div>
          <div className={`${styles.infoColumn} ${infoModifier}`}>
            <div className={styles.columnInner}>
              <div className={styles.infoWrap}>
                <h2 className={styles.title}>
                  {props.data.title}
                </h2>
                <Wysiwyg content={props.data.wysiwyg} noSpacing />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

TextImageGrid.propTypes = {
  data: PropTypes.object.isRequired
}

export default TextImageGrid
