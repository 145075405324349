import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import { VelocityComponent } from 'velocity-react'
import { connect } from 'react-redux'
import { Link } from 'gatsby'
import Icon from '../Icon/Icon'
import styles from './DesktopLangNav.module.scss'
import data from './DesktopLangNav.json'

const Overlay = props => {
  const overlay = (
    <div
      className={styles.overlay}
      onClick={() => {
        props.hide()
      }}
      style={{ display: props.visible ? 'block' : 'none' }}
    />
  )
  if (typeof window !== 'undefined') {
    return ReactDOM.createPortal(overlay, document.querySelector('#overlays'))
  }
  return <div />
}

const DefaultDesktopLangNav = props => (
  <div>
    <button
      className={styles.button}
      onClick={() => {
        props.toggle()
      }}
    >
      <div className={styles.buttonGrid}>
        <div className={styles.icon}>
          <Icon name='globe' />
        </div>
        <span className={styles.text}>
          {props.langData.locale.toUpperCase()}.
        </span>
      </div>
    </button>
    <VelocityComponent
      animation={{
        translateY: props.visible ? ['0px', '-5px'] : ['-5px', '0px']
      }}
      display={props.visible ? 'block' : 'none'}
      easing='easeInOutSine'
      duration={300}
    >
      <div className={styles.dropdown}>
        <VelocityComponent
          animation={{
            opacity: props.visible ? 1 : 0
          }}
          duration={300}
        >
          <div className={styles.inner}>
            <div className={styles.grid}>
              <div className={styles.column}>
                <div className={styles.titleGrid}>
                  <div className={styles.icon}>
                    <Icon name='globe' />
                  </div>
                  <div className={styles.title}>
                    {data.title[props.langData.locale]}
                  </div>
                </div>
              </div>
              <div className={styles.column}>
                <ul className={styles.list}>
                  {props.translations.map((item, i) => {
                    return (
                      <li key={i}>
                        <Link
                          to={item.path.replace('/grau-roig/', '/')}
                          activeClassName={styles.active}
                        >
                          {item.native_name}
                        </Link>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </div>
        </VelocityComponent>
      </div>
    </VelocityComponent>
    <Overlay
      visible={props.visible}
      hide={() => {
        props.toggle()
      }}
    />
  </div>
)

DefaultDesktopLangNav.propTypes = {
  langData: PropTypes.object.isRequired,
  toggle: PropTypes.func,
  visible: PropTypes.bool
}

const mapStateToProps = ({ langData }) => {
  return { langData }
}

const DesktopLangNav = connect(mapStateToProps)(DefaultDesktopLangNav)

export default DesktopLangNav
