import React from 'react'
import PropTypes from 'prop-types'
import LinkedRooms from '../LinkedRooms/LinkedRooms'
import styles from './HomeLinkedRooms.module.scss'

const HomeLinkedRooms = props => (
  <div className={styles.wrap}>
    <div className={styles.info}>
      <h2 className={styles.title}>
        {props.title}
      </h2>
      <div
        className={styles.content}
        dangerouslySetInnerHTML={{ __html: props.content}}
      />
      <div className={styles.overlay} />
    </div>
    <LinkedRooms
      items={props.items}
      noTitle
    />
  </div>
)

HomeLinkedRooms.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  items: PropTypes.array.isRequired
}

export default HomeLinkedRooms
