import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './BookForm.module.scss'
import data from './BookForm.json'
import BookFormField from '../BookFormField/BookFormField'
import BookFormDayPicker from '../BookFormDayPicker/BookFormDayPicker'
import BookFormSelect from '../BookFormSelect/BookFormSelect'
import { DateUtils } from 'react-day-picker'
import { subDays, format } from 'date-fns'

const VISIBLE_DATE_PLACEHOLDER = {
  es: 'dd.mm.aa',
  en: 'mm.dd.yy',
  ca: 'dd.mm.aa',
  fr: 'jj.mm.aa'
}
const VISIBLE_DATE_FORMAT = {
  es: 'DD.MM.YY',
  en: 'MM.DD.YY',
  ca: 'DD.MM.YY',
  fr: 'DD.MM.YY'
}
const BOOK_DATE_FORMAT = 'DD-MM-YYYY'
const BOOK_APP_URL = 'https://app.thebookingbutton.com/properties/grauroigdirect'

function formatVisibleDate (date, locale) {
  if (date) {
    return format(date, VISIBLE_DATE_FORMAT[locale])
  } else {
    return VISIBLE_DATE_PLACEHOLDER[locale]
  }
}

function createBookUrl (locale, dates, guests) {
  let url = `${BOOK_APP_URL}?locale=${locale}`

  if (dates.arrival) {
    url += `&check_in_date=${format(dates.arrival, BOOK_DATE_FORMAT)}`
  }

  if (dates.departure) {
    if (!dates.arrival) {
      const arrival = subDays(dates.departure, 1)
      url += `&check_in_date=${format(arrival, BOOK_DATE_FORMAT)}`
    }

    url += `&check_out_date=${format(dates.departure, BOOK_DATE_FORMAT)}`
  }

  if (guests.adults) {
    url += `&number_adults=${guests.adults}`
  }

  if (guests.children) {
    url += `&number_children=${guests.children}`
  }

  return url
}

class BookForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      visible: {
        arrival: false,
        departure: false,
        adults: false,
        children: false
      },
      dates: {
        arrival: null,
        departure: null
      },
      guests: {
        adults: 0,
        children: 0
      }
    }
  }

  toggleControl (control) {
    let visibleFields = this.state.visible
    for (const field in visibleFields) {
      if (field !== control) {
        visibleFields[field] = false
      }
    }
    if (this.state.visible[control]) {
      this.setState({
        ...this.state,
        visible: {
          ...visibleFields,
          [control]: false
        }
      })
    } else {
      this.setState({
        ...this.state,
        visible: {
          ...visibleFields,
          [control]: true
        }
      })
    }
  }

  setDateInState (type, date) {
    this.setState({
      ...this.state,
      dates: {
        ...this.state.dates,
        [type]: date
      }
    })
  }

  setInverseDates (arrival, departure) {
    this.setState({
      ...this.state,
      dates: {
        arrival: departure,
        departure: arrival
      }
    })
  }

  compareDates (type, arrival, departure, currDate) {
    const isSameDay = DateUtils.isSameDay(arrival, departure)
    const arrivalIsBefore = DateUtils.isDayBefore(arrival, departure)
    if (!isSameDay && arrivalIsBefore) {
      this.setDateInState(type, currDate)
    } else if (!isSameDay && !arrivalIsBefore) {
      this.setInverseDates(arrival, departure)
    }
  }

  setDate (type, date) {
    if (type === 'arrival' && this.state.dates.departure) {
      this.compareDates(type, date, this.state.dates.departure, date)
    } else if (type === 'arrival') {
      this.setDateInState(type, date)
    } else if (type === 'departure' && this.state.dates.arrival) {
      this.compareDates(type, this.state.dates.arrival, date, date)
    } else if (type === 'departure') {
      this.setDateInState(type, date)
    }
  }

  render () {
    const buttonModifier = this.props.whiteButton ? styles.white : ''
    const commonProps = {
      dropdown: this.props.dropdown,
      pinned: this.props.pinned
    }
    const guestSelectItems = Array.from({length: 11}, (v, k) => {
      const value = k
      return {
        value,
        text: value.toString()
      }
    })
    const bookUrl = createBookUrl(this.props.locale, this.state.dates, this.state.guests)
    return (
      <div>
        <div className={styles.fields}>
          <BookFormField
            label={this.props.labels.arrival}
            childHasLabel
            value={formatVisibleDate(this.state.dates.arrival, this.props.locale)}
            handleClick={() => { this.toggleControl('arrival') }}
            childVisible={this.state.visible.arrival}
            desktopModal
            {...commonProps}
          >
            <BookFormDayPicker
              locale={this.props.locale}
              handleDateChange={(date) => {
                this.setDate('arrival', date)
                setTimeout(() => {
                  this.toggleControl('arrival')
                }, 400)
              }}
              arrival={this.state.dates.arrival}
              departure={this.state.dates.departure}
            />
          </BookFormField>
          <BookFormField
            label={this.props.labels.departure}
            childHasLabel
            value={formatVisibleDate(this.state.dates.departure, this.props.locale)}
            handleClick={() => { this.toggleControl('departure') }}
            childVisible={this.state.visible.departure}
            desktopModal
            {...commonProps}
          >
            <BookFormDayPicker
              locale={this.props.locale}
              handleDateChange={(date) => {
                this.setDate('departure', date)
                setTimeout(() => {
                  this.toggleControl('departure')
                }, 400)
              }}
              arrival={this.state.dates.arrival}
              departure={this.state.dates.departure}
            />
          </BookFormField>
          <BookFormField
            label={this.props.labels.adults}
            value={this.state.guests.adults.toString()}
            handleClick={() => { this.toggleControl('adults') }}
            childVisible={this.state.visible.adults}
            beta
            {...commonProps}
          >
            <BookFormSelect
              items={guestSelectItems}
              currentValue={this.state.guests.adults}
              handleValueChange={(value) => {
                this.setState({
                  guests: {
                    ...this.state.guests,
                    adults: value
                  }
                })
                setTimeout(() => {
                  this.toggleControl('adults')
                }, 400)
              }}
            />
          </BookFormField>
          <BookFormField
            label={this.props.labels.wordpress_children}
            value={`${this.state.guests.children}`}
            handleClick={() => { this.toggleControl('children') }}
            childVisible={this.state.visible.children}
            beta
            {...commonProps}
          >
            <BookFormSelect
              items={guestSelectItems}
              currentValue={this.state.guests.children}
              handleValueChange={(value) => {
                this.setState({
                  guests: {
                    ...this.state.guests,
                    children: value
                  }
                })
                setTimeout(() => {
                  this.toggleControl('children')
                }, 400)
              }}
            />
          </BookFormField>
        </div>
        {/* <button type='button' className={`${styles.button} ${buttonModifier}`}>
          <span>
            {this.props.labels.book}
          </span>
        </button> */}
        <a href={bookUrl} target='_blank' className={`${styles.button} ${buttonModifier}`}>
          <span>{this.props.labels.book}</span>
        </a>
      </div>
    )
  }
}

BookForm.propTypes = {
  locale: PropTypes.string.isRequired,
  pinned: PropTypes.bool.isRequired,
  labels: PropTypes.object.isRequired,
  dropdown: PropTypes.bool
}

export default BookForm
