import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styles from './MobileBetaNav.module.scss'

const MobileBetaNav = ({ items }) => (
  <div className={styles.wrap}>
    <ul className={styles.list}>
      {items.map((item, i) => {
        const url = item.url.replace(/https?:\/\/[^\/]+/i, '')
        return (
          <li key={i}>
            <Link to={url}>
              <span dangerouslySetInnerHTML={{ __html: item.title }} />
            </Link>
          </li>
        )
      })}
    </ul>
  </div>
)

MobileBetaNav.propTypes = {
  items: PropTypes.array.isRequired
}

export default MobileBetaNav
