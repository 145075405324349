import React from 'react'
import PropTypes from 'prop-types'
import styles from './SmallWysiwyg.module.scss'

const SmallWysiwyg = props => (
  <div className={styles.content} dangerouslySetInnerHTML={{ __html: props.content}} />
)

SmallWysiwyg.propTypes = {
  content: PropTypes.string.isRequired
}

export default SmallWysiwyg
