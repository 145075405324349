import React from 'react'
import PropTypes from 'prop-types'
import styles from './LinkedRooms.module.scss'
import SectionTitle from '../SectionTitle/SectionTitle'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import Icon from '../Icon/Icon'
import tr from './LinkedRooms.json'

const LinkedRooms = props => {
  let sectionTitle = null
  if (!props.noTitle) {
    sectionTitle = <SectionTitle text={tr.title[props.locale]} />
  }
  let modifier = ''
  if (props.noSpacing) {
    modifier = styles.noSpacing
  }
  let widthModifier = ''
  if (props.items.length === 3) {
    widthModifier = styles.oneThird
  } else if (props.items.length === 4) {
    widthModifier = styles.oneQuarter
  } else if (props.items.length === 5) {
    widthModifier = styles.oneFifth
  }

  return (
    <div className={`${styles.wrap} ${modifier}`}>
      {sectionTitle}
      <div className={styles.grid}>
        {props.items.map(item => {
          const backupSourceUrl = item.linked_image.source_url
          const imgAltText = item.linked_image.alt_text
          const localFile = item.linked_image.localFile
          const sizes = localFile
            ? localFile.childImageSharp.croppedSizes
            : null
          const duotoneSizes = localFile
            ? localFile.childImageSharp.croppedSizesDuotone
            : null
          return (
            <div
              key={item.wordpress_id}
              className={`${styles.item} ${widthModifier}`}
            >
              <Link to={item.path}>
                <div className={styles.itemGrid}>
                  <div className={styles.imageColumn}>
                    <div className={styles.image}>
                      {sizes && <Img fluid={sizes} alt={imgAltText} />}
                      {!sizes && (
                        <div className={styles.imageBackup}>
                          <img src={backupSourceUrl} alt={imgAltText} />
                        </div>
                      )}
                      <div className={styles.duotoneImage}>
                        {duotoneSizes && (
                          <Img fluid={duotoneSizes} alt={imgAltText} />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={styles.infoColumn}>
                    <div className={styles.titleGrid}>
                      <div className={styles.title}>
                        <span>{item.short_title}</span>
                      </div>
                      <div className={styles.icon}>
                        <Icon name='smallArrowRightGold' />
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          )
        })}
      </div>
    </div>
  )
}

LinkedRooms.propTypes = {
  items: PropTypes.array.isRequired,
  noTitle: PropTypes.bool,
  noSpacing: PropTypes.bool
}

export default LinkedRooms
