import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { VelocityComponent } from 'velocity-react'
import DropdownAnimation from '../DropdownAnimation/DropdownAnimation'
import AnchorScrollButton from '../AnchorScrollButton/AnchorScrollButton'
import Icon from '../Icon/Icon'
import styles from './MobileMainNav.module.scss'

const MobileMainNav = props => (
  <div className={styles.wrap}>
    <div className={styles.grid}>
      {props.items.map((item, i) => {
        return (
          <Group
            key={i}
            index={i}
            item={item}
            visibleGroup={props.visibleGroup}
            setVisibleGroup={visibleGroup => {
              props.showGroup(visibleGroup)
            }}
            hideNavs={() => {
              props.hideNavs()
            }}
            locationKey={props.locationKey}
          />
        )
      })}
    </div>
  </div>
)

class Group extends Component {
  render () {
    let children

    if (this.props.item.attr === 'no-link') {
      children = this.props.item.wordpress_children
    } else {
      const self = {
        title: this.props.item.title,
        attr: this.props.item.attr,
        url: this.props.item.url
      }
      children = [self, ...this.props.item.wordpress_children]
    }

    const visible = this.props.index === this.props.visibleGroup
    let opened = false
    return (
      <div
        className={styles.group}
        ref={el => {
          this.group = el
        }}
      >
        <AlphaButton
          text={this.props.item.title}
          clickHandler={() => {
            if (opened) {
              this.props.setVisibleGroup(null)
            } else {
              this.props.setVisibleGroup(this.props.index)
            }
          }}
        />
        <DropdownAnimation
          animation={visible ? 'slideDown' : 'slideUp'}
          easing='easeInOutSine'
          duration={300}
          delay={visible ? 0 : 300}
          beginCb={() => {
            if (this.group) {
              this.group.setAttribute('style', 'pointer-events: none;')
            }
          }}
        >
          <div className={styles.listContainer}>
            <VelocityComponent
              animation={{
                opacity: visible ? 1 : 0
              }}
              duration={300}
              delay={visible ? 300 : 0}
              complete={() => {
                if (visible) {
                  opened = true
                } else {
                  opened = false
                }
                if (this.group) {
                  this.group.removeAttribute('style')
                }
              }}
            >
              <List
                data={children}
                hideNavs={() => {
                  this.props.hideNavs()
                }}
                locationKey={this.props.locationKey}
              />
            </VelocityComponent>
          </div>
        </DropdownAnimation>
      </div>
    )
  }
}

const AlphaButton = props => {
  const modifier = props.isDisabled ? styles.isDisabled : ''
  return (
    <div
      role='button'
      tabIndex='0'
      onClick={() => {
        props.clickHandler()
      }}
      className={`${styles.alpha} ${modifier}`}
    >
      <div className={styles.alphaGrid}>
        <div className={styles.alphaText}>{props.text}</div>
        <div className={styles.alphaIcon}>
          <Icon name='goldCaret' />
        </div>
      </div>
    </div>
  )
}

const List = props => {
  return (
    <ul className={styles.list}>
      {props.data.map((child, i) => {
        const url = child.url.replace(/https?:\/\/[^\/]+/i, '')
        const isHiddenOnMobile =
          child.classes && child.classes.indexOf('hideOnMobile') > -1
        let content = null
        if (child.attr.indexOf('#') !== -1) {
          content = (
            <AnchorScrollButton
              title={child.title}
              url={url}
              attr={child.attr}
              locationKey={props.locationKey}
              onClickCb={() => {
                props.hideNavs()
              }}
            />
          )
        } else {
          content = (
            <Link
              to={url}
              onClick={() => {
                props.hideNavs()
              }}
            >
              {child.title}
            </Link>
          )
        }
        return (
          <li key={i} className={isHiddenOnMobile ? styles.hideOneMobile : ''}>
            {content}
          </li>
        )
      })}
    </ul>
  )
}

MobileMainNav.propTypes = {
  items: PropTypes.array.isRequired,
  hideNavs: PropTypes.func,
  showGroup: PropTypes.func,
  visibleGroup: PropTypes.number,
  locationKey: PropTypes.string.isRequired
}

export default MobileMainNav
