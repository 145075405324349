import React, { Component } from 'react'
import PropTypes from 'prop-types'
import LinkedRooms from '../LinkedRooms/LinkedRooms'
import LinkedRestaurants from '../LinkedRestaurants/LinkedRestaurants'
import MegamenuBook from '../MegamenuBook/MegamenuBook'
import DesktopMainNav from '../DesktopMainNav/DesktopMainNav'
import MegamenuContact from '../MegamenuContact/MegamenuContact'
import styles from './Megamenu.module.scss'

class Megamenu extends Component {
  componentWillUpdate (nextProps) {
    const diffProps = this.props.isHovering !== nextProps.isHovering
    if (diffProps) {
      this.props.isActive(nextProps.isHovering)
    }
  }

  render () {
    let child = null
    if (this.props.type === 'rooms') {
      child = (
        <div className={styles.container}>
          <LinkedRooms items={this.props.linkedRooms} noTitle noSpacing />
        </div>
      )
    } else if (this.props.type === 'restaurants') {
      child = (
        <div className={styles.container}>
          <LinkedRestaurants
            items={this.props.linkedRestaurants}
            noTitle
            noSpacing
          />
        </div>
      )
    } else if (this.props.type === 'book') {
      child = (
        <MegamenuBook
          bookContent={this.props.bookContent}
          locale={this.props.locale}
        />
      )
    } else if (this.props.type === 'mainNav') {
      child = (
        <DesktopMainNav
          locale={this.props.locale}
          items={this.props.mainNav}
          locationKey={this.props.locationKey}
        />
      )
    } else if (this.props.type === 'contact') {
      child = <MegamenuContact data={this.props.contact} />
    }
    return <div className={styles.wrap}>{child}</div>
  }
}

Megamenu.propTypes = {
  linkedRooms: PropTypes.array.isRequired,
  linkedRestaurants: PropTypes.array.isRequired,
  contact: PropTypes.object.isRequired,
  bookContent: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  mainNav: PropTypes.array.isRequired,
  locationKey: PropTypes.string.isRequired
}

export default Megamenu
