import React, { Component } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { MediaQueryProvider, MediaQuery } from 'react-responsive-hoc'
import Wysiwyg from '../Wysiwyg/Wysiwyg'
import ImageGallery from '../ImageGallery/ImageGallery'
import FeaturedText from '../FeaturedText/FeaturedText'
import BookRestaurant from '../BookRestaurant/BookRestaurant'
import Amenities from '../Amenities/Amenities'
import FeaturedPage from '../FeaturedPage/FeaturedPage'
import LinkedRooms from '../LinkedRooms/LinkedRooms'
import LinkedRestaurants from '../LinkedRestaurants/LinkedRestaurants'
import HomePillars from '../HomePillars/HomePillars'
import HomeLocation from '../HomeLocation/HomeLocation'
import HomeLinkedRooms from '../HomeLinkedRooms/HomeLinkedRooms'
import HomeLinkedRestaurants from '../HomeLinkedRestaurants/HomeLinkedRestaurants'
import TextImageGrid from '../TextImageGrid/TextImageGrid'
import LinkedActivityTypes from '../LinkedActivityTypes/LinkedActivityTypes'
import Pswp from '../Pswp/Pswp'
import StandaloneBookContact from '../StandaloneBookContact/StandaloneBookContact'
import StandaloneBookRoom from '../StandaloneBookRoom/StandaloneBookRoom'
import Activities from '../Activities/Activities'
import ContactGrid from '../ContactGrid/ContactGrid'
import PromoGrid from '../PromoGrid/PromoGrid'
import GiftCards from '../GiftCards/GiftCards'
import DownloadDocument from '../DownloadDocument/DownloadDocument'
import HomeLinkedPosts from '../HomeLinkedPosts/HomeLinkedPosts'
import Gallery from '../Gallery/Gallery'

const createImagesArray = blocks => {
  if (blocks.length) {
    let arr = []
    blocks.forEach(block => {
      if (block.image_gallery_grid) {
        block.image_gallery_grid.forEach(({ row }) => {
          const left = { ...row.left }
          const right = { ...row.right }
          if (row.caption_left) left.caption = row.caption_left
          if (row.caption_right) right.caption = row.caption_right
          arr.push(left)
          arr.push(right)
        })
      } else if (block.image_gallery_single) {
        block.image_gallery_single.forEach(({ row }) => {
          const obj = { ...row.image }
          if (row.caption) obj.caption = row.caption
          arr.push(obj)
        })
      } else if (block.__typename === 'WordPressAcf_gallery') {
        block.items.forEach((item) => {
          arr.push(item)
        })
      }
    })
    return arr
  }
}

class FlexibleContent extends Component {
  constructor (props) {
    super(props)
    this.state = {
      pswpVisible: false,
      pswpCurrent: 0
    }
    this.handlePswpVisibility = this.handlePswpVisibility.bind(this)
  }

  handlePswpVisibility (open, index) {
    this.setState({
      pswpVisible: open,
      pswpCurrent: index
    })
  }

  componentWillMount () {
    if (this.props.galleryData) {
      const blocksData = this.props.data.acf.blocks_page
      if (blocksData) {
        let images = createImagesArray(blocksData)
        if (images) {
          this.props.galleryData(images)
        }
      }
    }
  }

  render () {
    const blocksData = this.props.data.acf.blocks_page

    let blocks = []
    if (blocksData) {
      blocks = blocksData
    }

    let pswp = null
    let images = createImagesArray(blocks)
    if (typeof images !== 'undefined' && images.length > 0) {
      pswp = (
        <Pswp
          images={images}
          current={this.state.pswpCurrent}
          isVisible={this.state.pswpVisible}
          onVisibilityChange={this.handlePswpVisibility}
        />
      )
    }

    const openPswpByIndex = id => {
      const index = images.findIndex(image => {
        return image.id === id
      })
      this.handlePswpVisibility(true, index)
    }

    return (
      <div>
        {pswp}
        {blocks.map((block, index) => {
          if (block.wysiwyg) {
            let sideNote = null
            if (block.side_note) {
              sideNote = block.side_note
            }
            return (
              <div key={block.id}>
                <Wysiwyg
                  content={block.wysiwyg}
                  sideNote={sideNote}
                  leftOverlay={this.props.leftOverlay}
                  centerOverlay={this.props.centerOverlay}
                />
              </div>
            )
          } else if (block.image_gallery_grid) {
            return (
              <div key={block.id}>
                <MediaQueryProvider width={1440} height={900}>
                  <MediaQuery query='(min-width: 978px)'>
                    <div>
                      <ImageGallery
                        data={block.image_gallery_grid}
                        openPswpById={openPswpByIndex}
                        locale={this.props.locale}
                      />
                    </div>
                  </MediaQuery>
                </MediaQueryProvider>
              </div>
            )
          } else if (block.image_gallery_single) {
            return (
              <div key={block.id}>
                <MediaQueryProvider width={1440} height={900}>
                  <MediaQuery query='(min-width: 978px)'>
                    <div>
                      <ImageGallery
                        data={block.image_gallery_single}
                        openPswpById={openPswpByIndex}
                        locale={this.props.locale}
                      />
                    </div>
                  </MediaQuery>
                </MediaQueryProvider>
              </div>
            )
          } else if (block.featured_text) {
            return (
              <div key={block.id}>
                <FeaturedText
                  content={block.featured_text}
                  noBorders={block.hide_featured_text_borders}
                />
              </div>
            )
          } else if (block.book_restaurant) {
            const contactData = this.props.data.mainHeader.megamenu.book
              .restaurant.dropdown
            const contact = {
              title: contactData.label,
              list: contactData.content.book_contact.list,
              addendum: contactData.content.book_contact.addendumm
            }
            return (
              <div key={block.id}>
                <BookRestaurant
                  data={block.book_restaurant}
                  contact={contact}
                />
              </div>
            )
          } else if (block.amenities) {
            return (
              <div key={block.id}>
                <Amenities data={block.amenities} locale={this.props.locale} />
              </div>
            )
          } else if (block.featured_page) {
            let featuredProps = {}
            featuredProps.image = block.featured_page.image
            featuredProps.title = block.featured_page.title
            featuredProps.description = block.featured_page.description
            featuredProps.path = block.featured_page.path
            featuredProps.locale = this.props.locale
            return (
              <div key={block.id}>
                <FeaturedPage {...featuredProps} />
              </div>
            )
          } else if (block.linked_rooms) {
            return (
              <div key={block.id}>
                <LinkedRooms
                  items={block.linked_rooms}
                  locale={this.props.locale}
                />
              </div>
            )
          } else if (block.linked_restaurants) {
            return (
              <div key={block.id}>
                <LinkedRestaurants
                  items={block.linked_restaurants}
                  locale={this.props.locale}
                />
              </div>
            )
          } else if (block.home_pillars) {
            return (
              <div key={block.id}>
                <HomePillars items={block.home_pillars} />
              </div>
            )
          } else if (block.centered_wysiwyg) {
            return (
              <div key={block.id}>
                <Wysiwyg content={block.centered_wysiwyg} centered />
              </div>
            )
          } else if (block.home_location) {
            return (
              <div key={block.id}>
                <HomeLocation data={block.home_location} />
              </div>
            )
          } else if (
            block.__typename === 'WordPressAcf_home_linked_rooms_component'
          ) {
            if (this.props.data.mainHeader) {
              const linkedRooms = this.props.data.mainHeader.megamenu
                .linked_rooms
              return (
                <div key={block.id}>
                  <HomeLinkedRooms
                    title={block.title}
                    content={block.content}
                    items={linkedRooms}
                  />
                </div>
              )
            }
          } else if (
            block.__typename ===
            'WordPressAcf_home_linked_restaurants_component'
          ) {
            if (this.props.data.mainHeader) {
              const linkedRestaurants = this.props.data.mainHeader.megamenu
                .linked_restaurants
              return (
                <div key={block.id}>
                  <HomeLinkedRestaurants
                    title={block.title}
                    content={block.content}
                    items={linkedRestaurants}
                  />
                </div>
              )
            }
          } else if (block.text_image_grid) {
            return (
              <div key={block.id}>
                <TextImageGrid data={block.text_image_grid} />
              </div>
            )
          } else if (block.linked_activity_types) {
            return (
              <div key={block.id}>
                <LinkedActivityTypes
                  sectionTitle={block.section_title}
                  items={block.linked_activity_types}
                />
              </div>
            )
          } else if (block.standalone_book_contact) {
            return (
              <div key={block.id}>
                <StandaloneBookContact data={block.standalone_book_contact} />
              </div>
            )
          } else if (block.standalone_book_room) {
            const bookRoom = this.props.data.mainHeader.megamenu.book.room
            const label = bookRoom.button.label
            const title = bookRoom.dropdown.title
            const localFile = block.standalone_book_room.image.localFile
            const imageSizes = localFile
              ? localFile.childImageSharp.fluid
              : null
            const formLabels =
              bookRoom.dropdown.content.book_form.book_form_labels
            return (
              <div key={block.id}>
                <StandaloneBookRoom
                  label={label}
                  title={title}
                  imageSizes={imageSizes}
                  formLabels={formLabels}
                  locale={this.props.locale}
                />
              </div>
            )
          } else if (block.linked_activities) {
            const contactSections = this.props.data.mainHeader.megamenu.contact
              .sections
            const contact = contactSections.find(
              section => section.contact_list
            )
            if (block.linked_activities.length > 0) {
              return (
                <Activities
                  key={block.id}
                  items={block.linked_activities}
                  shortTitle={this.props.data.acf.short_title}
                  longTitle={this.props.data.acf.long_title}
                  contact={contact}
                />
              )
            }
          } else if (
            block.__typename === 'WordPressAcf_contact_grid_component'
          ) {
            return (
              <ContactGrid
                key={block.id}
                data={this.props.data.mainHeader.megamenu.contact}
              />
            )
          } else if (
            block.__typename === 'WordPressAcf_linked_promotions_component'
          ) {
            const bookLabel = this.props.data.mainHeader.megamenu.book.room
              .dropdown.label
            const promotions = block.promotions.map(promo => {
              const { title } = promo
              const {
                subtitle,
                dates,
                gallery,
                sliderGallery,
                flyerImage,
                intro,
                description,
                features,
                addendum
              } = promo.acf
              return {
                title,
                subtitle,
                dates,
                gallery,
                sliderGallery,
                flyerImage,
                intro,
                description,
                features,
                addendum
              }
            })

            return (
              <PromoGrid
                key={block.id}
                items={promotions}
                bookLabel={bookLabel}
              />
            )
          } else if (
            block.__typename === 'WordPressAcf_linked_giftcards_component'
          ) {
            const contactSections = this.props.data.mainHeader.megamenu.contact
              .sections
            const contact = contactSections.find(
              section => section.contact_list
            )
            return (
              <GiftCards
                key={block.id}
                items={block.giftcards}
                contact={contact}
              />
            )
          } else if (
            block.__typename === 'WordPressAcf_download_document_component'
          ) {
            const { id, id_html_attr, title, subtitle, file } = block
            return (
              <DownloadDocument
                key={id}
                idHtmlAttr={id_html_attr}
                title={title}
                subtitle={subtitle}
                sourceUrl={file.source_url}
                locale={this.props.locale}
              />
            )
          } else if (
            block.__typename === 'WordPressAcf_latest_posts_component'
          ) {
            const { id } = block
            return <HomeLinkedPosts key={id} locale={this.props.locale} />
          } else if (
            block.__typename === 'WordPressAcf_gallery'
          ) {
            return <Gallery
              key={block.id}
              title={block.title}
              items={block.items}
              index={index}
              openPswpById={openPswpByIndex}
              locale={this.props.locale}
            />
          }
        })}
      </div>
    )
  }
}

FlexibleContent.propTypes = {
  data: PropTypes.object,
  locale: PropTypes.string
}

export default FlexibleContent

export const blocksFragment = graphql`
  fragment FlexibleContentData on wordpress__PAGE {
    title
    acf {
      short_title
      blocks_page {
        __typename
        ... on WordPressAcf_wysiwyg_content {
          id
          wysiwyg
          side_note
        }
        ... on WordPressAcf_image_gallery {
          id
          image_gallery_grid {
            row {
              widths
              left {
                id
                source_url
                alt_text
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1920, quality: 75) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
                media_details {
                  width
                  height
                }
              }
              right {
                id
                source_url
                alt_text
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1920, quality: 75) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
                media_details {
                  width
                  height
                }
              }
              caption_left
              caption_right
            }
          }
        }
        ... on WordPressAcf_image_gallery_single_image {
          id
          image_gallery_single {
            row {
              layout
              image {
                id
                source_url
                alt_text
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1920, quality: 75) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
                media_details {
                  width
                  height
                }
              }
              caption
            }
          }
        }
        ... on WordPressAcf_featured_text_component {
          id
          featured_text
          hide_featured_text_borders
        }
        ... on WordPressAcf_book_restaurant_component {
          id
          book_restaurant {
            title
            description
            button_text
            image {
              alt_text
              source_url
              localFile {
                childImageSharp {
                  fixed(width: 275, height: 360, quality: 75) {
                    ...GatsbyImageSharpFixed_noBase64
                  }
                }
              }
            }
          }
        }
        ... on WordPressAcf_amenities_content {
          id
          amenities {
            title
            intro
            list {
              item
            }
            list_intro
            list_addendum
          }
        }
        ... on WordPressAcf_featured_page_component {
          id
          featured_page {
            title
            slug
            description
            path
            image {
              alt_text
              source_url
              ...CroppedHorImageFiles
            }
          }
        }
        ... on WordPressAcf_linked_rooms_component {
          id
          linked_rooms {
            wordpress_id
            slug
            link
            path
            short_title
            linked_image {
              alt_text
              source_url
              ...CroppedSquareImageFiles
            }
          }
        }
        ... on WordPressAcf_linked_restaurants_component {
          id
          linked_restaurants {
            wordpress_id
            slug
            link
            path
            short_title
            linked_image {
              alt_text
              source_url
              ...CroppedHorThreeByFourImageFiles
            }
          }
        }
        ... on WordPressAcf_home_pillars_component {
          id
          home_pillars {
            wordpress_id
            slug
            link
            path
            short_title
            linked_image {
              alt_text
              source_url
              ...CroppedSquareImageFiles
            }
          }
        }
        ... on WordPressAcf_home_centered_wysiwyg_component {
          id
          centered_wysiwyg
        }
        ... on WordPressAcf_home_location_component {
          id
          home_location {
            title
            content
            contact {
              text
              link
            }
            image {
              alt_text
              source_url
              localFile {
                childImageSharp {
                  fluid(
                    maxWidth: 1600
                    maxHeight: 545
                    duotone: { highlight: "#C7C4C1", shadow: "#332C2E" }
                  ) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
        }
        ... on WordPressAcf_home_linked_rooms_component {
          id
          title
          content
        }
        ... on WordPressAcf_home_linked_restaurants_component {
          id
          title
          content
        }
        ... on WordPressAcf_home_text_image_grid_component {
          id
          text_image_grid {
            title
            layout
            wysiwyg
            image {
              source_url
              alt_text
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
            background
          }
        }
        ... on WordPressAcf_linked_activity_types_component {
          id
          section_title
          linked_activity_types {
            title
            slug
            path
            short_title
            image {
              alt_text
              source_url
              ...CroppedSquareImageFiles
            }
          }
        }
        ... on WordPressAcf_standalone_book_contact_component {
          id
          standalone_book_contact {
            label
            title
            contact {
              text
              link
            }
            addendum
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
        }
        ... on WordPressAcf_standalone_book_room_component {
          id
          standalone_book_room {
            label
            title
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
        }
        ... on WordPressAcf_linked_activities_component {
          id
          linked_activities {
            title
            activity_type
            description
            gallery {
              id
              localFile {
                childImageSharp {
                  fluid(maxWidth: 600, maxHeight: 420) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
            book {
              title
              contact_list {
                text
                link
              }
            }
          }
        }
        ... on WordPressAcf_contact_grid_component {
          id
        }
        ... on WordPressAcf_linked_promotions_component {
          id
          promotions {
            title
            acf {
              subtitle
              dates
              gallery {
                id
                source_url
                alt_text
                ...CroppedSquareImageFiles
              }
              sliderGallery: gallery {
                id
                source_url
                alt_text
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 600, maxHeight: 420) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
              flyerImage: flyer_image {
                source_url
                alt_text
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 600, quality: 75) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
              intro
              description
              features {
                title
                list {
                  list_item
                }
              }
              addendum
            }
          }
        }
        ... on WordPressAcf_linked_giftcards_component {
          id
          giftcards {
            title
            acf {
              subtitle
              short_description
              intro
              description
              image {
                alt_text
                source_url
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 600, maxHeight: 420, quality: 75) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
              button: purchase_button {
                alphaText: alpha_text
                betaText: beta_text
              }
            }
          }
        }
        ... on WordPressAcf_download_document_component {
          id
          id_html_attr
          title
          subtitle
          file {
            source_url
          }
        }
        ... on WordPressAcf_latest_posts_component {
          id
        }
        ... on WordPressAcf_gallery {
          id
          title
          items {
            id
            source_url
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 75) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            media_details {
              width
              height
            }
          }
        }
      }
    }
  }
`
