import React from 'react'
import PropTypes from 'prop-types'
import BookHeader from '../BookHeader/BookHeader'
import BookForm from '../BookForm/BookForm'
import { MediaQueryProvider, MediaQuery } from 'react-responsive-hoc'
import Image from '../Image/Image'
import styles from './StandaloneBookRoom.module.scss'

const StandaloneBookRoom = props => {
  const label = props.label
  const title = props.title
  const locale = props.locale
  const formLabels = props.formLabels
  const sizes = props.imageSizes

  return (
    <div className={styles.wrap}>
      <div className={styles.info}>
        <BookHeader label={label} title={title} />
        <BookForm locale={locale} labels={formLabels} pinned={false} />
      </div>
      {sizes &&
        <MediaQueryProvider width={1440} height={900}>
          <MediaQuery query='(min-width: 427px)'>
            <div className={styles.image}>
              <Image
                fluid={sizes}
                imageProps={{
                  style: {
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0
                  }
                }}
            />
            </div>
          </MediaQuery>
        </MediaQueryProvider>
      }
    </div>
  )
}

StandaloneBookRoom.propTypes = {
  locale: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  imageSizes: PropTypes.object.isRequired,
  formLabels: PropTypes.object.isRequired
}

export default StandaloneBookRoom
