import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './BookHeader.module.scss'

class BookHeader extends Component {
  render () {
    const modifier = this.props.noTopPadding ? styles.noTopPadding : ''
    return (
      <div className={`${styles.header} ${modifier}`}>
        <div className={styles.label}>
          {this.props.label}
        </div>
        <div className={styles.title}>
          {this.props.title}
        </div>
      </div>
    )
  }
}

BookHeader.propTypes = {
  label: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  noTopPadding: PropTypes.bool
}

export default BookHeader
