import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql, Link } from 'gatsby'
import styles from './MainNavBlog.module.scss'

const tr = {
  viewAll: {
    es: 'Ver todo',
    ca: 'Veure tot',
    en: 'View all',
    fr: 'Voir tout'
  }
}

/**
 * @param {Array} edges
 * @param {String} locale
 */
function getCurrentLocaleEdges(edges, locale) {
  return edges.filter(({ node }) => {
    return node.wpml_language_information.language_code === locale
  })
}

const MainNavBlog = ({ locale }) => {
  return (
    <StaticQuery
      query={graphql`
        query MainNavBlogQuery {
          posts: allWordpressPost {
            edges {
              node {
                id
                path
                title
                categories {
                  id
                  name
                }
                wpml_language_information {
                  language_code
                }
              }
            }
          }
          blogPages: allWordpressPage(
            filter: { template: { eq: "page-templates/Blog.php" } }
          ) {
            edges {
              node {
                path
                title
                wpml_language_information {
                  language_code
                }
              }
            }
          }
        }
      `}
      render={data => {
        const allPosts = getCurrentLocaleEdges(data.posts.edges, locale)

        if (allPosts.length === 0) return null

        const posts = allPosts.slice(0, 3)
        const blogPageEdges = getCurrentLocaleEdges(
          data.blogPages.edges,
          locale
        )

        if (blogPageEdges.length === 0) return null

        const blogPagePath = blogPageEdges[0].node.path
        const blogPageTitle = getCurrentLocaleEdges(
          data.blogPages.edges,
          locale
        )[0].node.title
        return (
          <div className={styles.container}>
            <div className={styles.grid}>
              <div className={styles.titleCol}>
                <div className={styles.title}>
                  <Link to={blogPagePath}>{blogPageTitle}</Link>
                </div>
                <div className={styles.viewAll}>
                  <Link to={blogPagePath}>{tr.viewAll[locale]}</Link>
                </div>
              </div>
              <div className={styles.postsCol}>
                <div>
                  {posts.map(({ node }) => {
                    const { id, path, title, categories } = node
                    return (
                      <div key={id} className={styles.post}>
                        {categories && (
                          <ul className={styles.categories}>
                            {categories.map(({ id, name }) => {
                              return <li key={id}>{name}</li>
                            })}
                          </ul>
                        )}
                        <div className={styles.postTitle}>
                          <Link to={path} title={title}>
                            {title}
                          </Link>
                        </div>
                        {/* {subtitle && (
                          <div className={styles.postSubtitle}>{subtitle}</div>
                        )} */}
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        )
      }}
    />
  )
}

MainNavBlog.propTypes = {
  locale: PropTypes.string.isRequired
}

export default MainNavBlog
