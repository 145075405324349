import React from 'react'
import PropTypes from 'prop-types'
import styles from './FeaturedText.module.scss'

const FeaturedText = props => {
  let modifier = ''
  if (props.noBorders) {
    modifier = styles.noBorders
  }
  return (
    <div className={styles.wrap}>
      <div
        className={`${styles.content} ${modifier}`}
        dangerouslySetInnerHTML={{ __html: props.content }}
      />
    </div>
  )
}

FeaturedText.propTypes = {
  content: PropTypes.string.isRequired
}

export default FeaturedText
