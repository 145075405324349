import React from 'react'
import PropTypes from 'prop-types'
import styles from './Lead.module.scss'

const Lead = props => {
  let modifiers = []
  if (props.leftOverlay) modifiers.push(styles.leftOverlay)
  if (props.rightOverlay) modifiers.push(styles.rightOverlay)
  if (props.centerOverlay) modifiers.push(styles.centerOverlay)
  if (props.centered) modifiers.push(styles.centered)
  if (props.noSpacing) modifiers.push(styles.noSpacing)
  if (props.borderBottom) modifiers.push(styles.borderBottom)
  if (props.lightBg) modifiers.push(styles.lightBg)
  if (props.sph) modifiers.push(styles.sph)
  return (
    <div className={`${styles.lead} ${modifiers.join(' ')}`}>
      <div
        className={styles.text}
        dangerouslySetInnerHTML={{ __html: props.text }}
      />
    </div>
  )
}

Lead.propTypes = {
  text: PropTypes.string.isRequired
}

export default Lead
